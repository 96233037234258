import {useEffect, useRef} from "react";
import {
  getRentCategoryApi,
  getRentListApi,
  getRentMachineApi,
  getRentMachineDetailApi,
  getRentSiteApi
} from "../api/centerApi";

export const useGetRentSiteApi = (setters, token) => {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const getData = await getRentSiteApi(token);

        setters.setList?.(getData);
      } catch (e) {
        console.error(e);
      }
    };

    fetchData();
  }, [token]);
}

export const useGetRentCategoryApi = (setters, token, data) => {
  const previousDataRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const getData = await getRentCategoryApi(token, data);

        setters.setList?.(getData.data);
        setters.setTotalCount?.(getData.totalCount);
        setters.setTotalPage?.(getData.totalPage);
      } catch (e) {
        console.error(e);
      }
    }

    if (JSON.stringify(previousDataRef.current) !== JSON.stringify(data)) {
      fetchData();
      previousDataRef.current = data;
    }
  }, [setters, token, data]);
}

export const useGetRentMachineApi = (setters, token, data) => {
  const previousDataRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const getData = await getRentMachineApi(token, data);

        setters.setList?.(getData.data);
        setters.setTotalCount?.(getData.totalCount);
        setters.setTotalPage?.(getData.totalPage);
      } catch (e) {
        console.error(e);
      }
    }

    if (JSON.stringify(previousDataRef.current) !== JSON.stringify(data)) {
      fetchData();
      previousDataRef.current = data;
    }
  }, [setters, token, data]);
}

export const useGetRentMachineDetailApi = (setters, token, data) => {
  const previousDataRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const getData = await getRentMachineDetailApi(token, data);

        setters.setDisabledDates?.(getData.rentalUnavailableDateList);
      } catch (e) {
        console.error(e);
      }
    }

    if (JSON.stringify(previousDataRef.current) !== JSON.stringify(data)) {
      fetchData();
      previousDataRef.current = data;
    }
  }, [setters, token, data]);
}

export const useGetRentListApi = (setters, token, data) => {
  const previousDataRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const getData = await getRentListApi(token, data);

        setters.setList?.(getData);
      } catch (e) {
        console.error(e);
      }
    }

    if (JSON.stringify(previousDataRef.current) !== JSON.stringify(data)) {
      fetchData();
      previousDataRef.current = data;
    }
  }, [setters, token, data]);
};
