import React, { useEffect, useState } from "react";
import { pageLoadingEvent } from "utils/commonUtil";
import Header from "components/header/Header";
import SearchMini from "assets/icon/searchMini.svg";
import Warning from "assets/icon/warning.svg";
import clock from "assets/icon/clock.svg";
import xButton from "assets/icon/xButton.svg";
import CommonPopup from "components/popup/CommonPopup";
import { searchByKeword, regalToAddress } from "api/search";
import BackHeader from "../../components/header/BackHeader";
import TradeBox from "../../components/trade/TradeBox";
import noImage from "assets/image/trade/noSmallImage.svg"

const SearchPage = () => {
    const [searchText, setSearchText] = useState("");
    const [searchText2, setSearchText2] = useState("");
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [recentSearches, setRecentSearches] = useState([]);
    const [isSearchCompleted, setIsSearchCompleted] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        pageLoadingEvent();
        loadRecentSearches();
    }, []);

    const loadRecentSearches = () => {
        const storedSearches = JSON.parse(localStorage.getItem("recentSearches")) || [];
        setRecentSearches(storedSearches);
    };

    const handleSearchClick = async (value) => {
        let searchValue = value || searchText;

        if (searchValue.length <= 1) {
            setIsPopupOpen(true);
        } else {
            if (!value) saveSearch(searchText);

            try {
                const response = await searchByKeword(searchValue);
                const resultList = response.data.list;

                // 응답에서 필요한 값들을 매핑하여 상태에 저장
                const updatedResults = resultList.map(result => ({
                    id: result.id,
                    tradeType: result.type, // type을 tradeType에 매핑
                    title: result.title,
                    nickname: result.writerName, // writerName을 nickname에 매핑
                    region: result.dong, // dong을 region에 매핑
                    imagePath: result.imageUrl, // imageUrl을 imagePath에 매핑
                    paymentCost: result.paymentCost,
                    isCostNegotiable: result.isCostNegotiable,
                    status: result.status,
                }));

                setSearchResults(updatedResults);
                setIsSearchCompleted(true);  // 검색 완료로 상태 변경
            } catch (error) {
                console.error("검색 중 오류 발생:", error);
                setSearchResults([]);
                setIsSearchCompleted(true);
            }
        }
    };


    const saveSearch = (searchTerm) => {
        let searches = JSON.parse(localStorage.getItem("recentSearches")) || [];
        searches = searches.filter(item => item !== searchTerm);
        searches.unshift(searchTerm);
        if (searches.length > 8) {
            searches.pop();
        }
        localStorage.setItem("recentSearches", JSON.stringify(searches));
        setRecentSearches(searches);
    };

    const clearRecentSearches = () => {
        localStorage.removeItem("recentSearches");
        setRecentSearches([]);
    };

    const deleteSearch = (searchTerm) => {
        const updatedSearches = recentSearches.filter(item => item !== searchTerm);
        setRecentSearches(updatedSearches);
        localStorage.setItem("recentSearches", JSON.stringify(updatedSearches));
    };

    return (
        <section className="container">
            <Header />
            <BackHeader title="검색하기" />
            <div className="content content2 scrollbar">
                <div className="inner">
                    <div className="search-input mt-20 pr-10 pl-10 pt-10 pb-10 d-flex a-center j-between">
                        <div className="d-flex a-center j-left searchInputBox">
                            <button className="searchBtn" onClick={() => handleSearchClick(null)}>
                                <img src={SearchMini} alt="돋보기" />
                            </button>
                            <input
                                className="input-lightGrayPlaceHolder pl-10 fs-24 fw-6 ml-10"
                                type="text"
                                placeholder="검색어를 입력하세요"
                                value={searchText2}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                    setSearchText2(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearchClick(null); // Enter 키를 누르면 handleSearchClick 실행
                                    }
                                }}
                            />

                        </div>

                        {isSearchCompleted && (
                            <button
                                className="backSearchBtn"
                                onClick={() => {
                                    setSearchText(''); // 입력값 초기화
                                    setSearchText2('');
                                    setIsSearchCompleted(false); // 최근 검색어 버튼들 다시 보이게
                                }}
                            >
                                <img src={xButton} alt="clear" />
                            </button>
                        )}
                    </div>
                </div>


                {!isSearchCompleted &&
                    <div className="d-flex j-between fs-24 mt-25 pl-25 pr-25">
                        <p className="fw-6">최근 검색어</p>
                        {recentSearches.length > 0 &&
                            <p className="fw-5 fc-mediumGray2 pointer" onClick={clearRecentSearches}>전체 삭제</p>
                        }
                    </div>
                }

                {isSearchCompleted ? (
                    <div className="mt-40">
                        {
                            searchResults.length > 0 ? (
                                searchResults.map((result, index) => (
                                    <TradeBox
                                        key={index}
                                        id={result.id}
                                        tradeType={result.tradeType}
                                        title={result.title}
                                        nickname={result.nickname}
                                        region={result.region}
                                        imagePath={result.imagePath || noImage}
                                        searchKeyword={searchText}
                                        price={result.paymentCost}
                                        costNegotiate={result.isCostNegotiable}
                                        status={result.status}
                                    />
                                ))
                            ) : (
                                <div className="ta-center">
                                    <p className="fs-28 fw-6 mb-5 ls-2">입력하신 검색 결과가 없어요</p>
                                    <p className="fs-24 fc-mediumGray2">검색어를 다시 입력해 주세요.</p>
                                </div>
                            )
                        }
                    </div>
                ) : (
                    recentSearches.length === 0 ? (
                        <div className="mt-40 fw-5 fs-24 fc-mediumGray2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                            최근 검색어 내역이 없어요
                        </div>
                    ) : (
                        <ul className="recent-searches-list inner d-flex f-column a-center j-center mt-25 fw-5 fs-24">
                            {recentSearches.map((search, index) => (
                                <React.Fragment key={index}>
                                    <li
                                        className="fs-24 fw-4"
                                        style={{
                                            listStyleType: 'none',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            width: 'calc(550 * 100vw / 600)',
                                            minWidth: 310,
                                            maxWidth: 550
                                        }}
                                    >
                                        <span
                                            className="pointer"
                                            style={{ display: 'flex', alignItems: 'center', width: '50%' }}
                                            onClick={() => {
                                                setSearchText(search);
                                                setSearchText2(search);
                                                handleSearchClick(search);
                                            }}
                                        >
                                            <img src={clock} alt="" style={{ marginRight: '10px', height: '24px', width: '24px' }} />
                                            {search}
                                        </span>
                                        <button style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                                            onClick={() => deleteSearch(search)}>
                                            <img className="d-block" src={xButton} alt="" style={{ width: '45px', height: '45px' }} />
                                        </button>
                                    </li>
                                    {index < recentSearches.length - 1 && (
                                        <div style={{ width: 'calc(100% - 50px)', borderBottom: '1px solid #ddd', marginTop: '10px', marginBottom: '10px' }}></div>
                                    )}
                                </React.Fragment>
                            ))}
                        </ul>
                    )
                )}
                <div className="space mb-30" />
            </div>
            <CommonPopup
                image={Warning}
                title1='검색어를 '
                title2='최소 2자리 이상'
                title3=' 입력해주세요.'
                text='2자리 이상 입력후 검색해주세요.'
                btnText1='확인'
                status={isPopupOpen}
                setStatus={setIsPopupOpen}
                btnEvent1={false}
            />
        </section>
    );
};

export default SearchPage;
