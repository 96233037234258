import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { pageLoadingEvent, wasUrl } from "utils/commonUtil";
import Header from "components/header/Header";
import ImageUploader from "pages/menu/ImageUploader";
import AddressSelector from "pages/menu/AddressSelector";
import TradeDropdownPopup from "pages/trade/TradeDropdownPopup";
import RightArrowIcon from "assets/icon/rightArrow.svg";
import DaumPostcode from 'react-daum-postcode';
import reqImg from "assets/icon/reqImg.svg";
import resImg from "assets/icon/resImg.svg";
import circleCheckButton from "assets/icon/circle-checkButton.svg";
import circleCheckButtonFill from "assets/icon/circle-checkButtion-fill.svg";
import radio from "assets/icon/radio.svg";
import radioFill from "assets/icon/radio-fill.svg";
import { ReactComponent as SelectedRadio } from "assets/icon/radio-fill.svg";
import axios from "axios";
import BackHeader from "components/header/BackHeader";
import BackHeaderAddress from "components/header/BackHeaderAddress";
import CommonBarBtn from "components/button/CommonBarBtn";
import { useCookies } from 'react-cookie';
import CommonPopup2 from "components/popup/CommonPopup2";

const WritingPage = ({ writeState = '신규' }) => {
    useEffect(() => {
        pageLoadingEvent();
    }, []);

    const navigate = useNavigate();
    const location = useLocation();
    const [files, setFiles] = useState([]);
    const [isSimpleAddressPopupOpen, setIsSimpleAddressPopupOpen] = useState(false);
    const [displayAddress, setDisplayAddress] = useState(null);
    const [searchAddress, setSearchAddress] = useState(location.state?.selectedAddress || "");
    const [latLng, setLatLng] = useState({ lat: null, lng: null }); // 위경도 값을 저장할 상태
    const [images, setImages] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isTradePopupOpen, setIsTradePopupOpen] = useState(false);
    const [price, setPrice] = useState("");
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [isPriceNegotiable, setIsPriceNegotiable] = useState(false);
    const [isInputDisabled, setIsInputDisabled] = useState(false);
    const [villageHallId, setVillageHallId] = useState(null);

    // 추가된 state
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedSubCity, setSelectedSubCity] = useState("");
    const [legalCode, setLegalCode] = useState(null);

    const [cookies] = useCookies(['authToken']);  // useCookies를 컴포넌트 최상단에서 호출
    const authToken = cookies.authToken;

    useEffect(() => {
        if (location.state?.fromSearch) {
            setSearchAddress(location.state.selectedAddress);
            setDisplayAddress("간편하게 읍, 면, 리를 선택해보세요");
        }
    }, [location.state]);

    useEffect(() => {
        if (searchAddress) {
        }
    }, [searchAddress]);

    useEffect(() => {
        if (displayAddress && !searchAddress) {
        }
    }, [displayAddress]);

    useEffect(() => {
        const writeBtn = document.getElementById('writeBtn');
        writeBtn.addEventListener('click', handleSubmit);

        return () => {
            writeBtn.removeEventListener('click', handleSubmit);
        };
    });


    const handleOpenPopup = () => setIsPopupOpen(true);
    const handleOpenTradePopup = () => setIsTradePopupOpen(true);
    const handleClosePopup = () => setIsPopupOpen(false);

    const handleSimpleAddressPopupToggle = () => {
        setIsSimpleAddressPopupOpen(!isSimpleAddressPopupOpen);
    };

    const handlePostCode = async (data) => {
        const { jibunAddress, bcode } = data;  // 지번주소(jibunAddress)만 사용
        setSearchAddress(jibunAddress);  // 지번주소를 searchAddress로 설정
        setDisplayAddress("");  // displayAddress는 비우기

        try {
            const url = `https://dapi.kakao.com/v2/local/search/address.json?query=${jibunAddress}`;
            const getLatLng = await axios.get(url, {
                headers: { Authorization: "KakaoAK 7877170379e76530c72097c01a586c13" }
            });

            const latLngData = getLatLng.data.documents;

            if (latLngData.length > 0) {
                const { y: lat, x: lng } = latLngData[0];
                setLatLng({ lat: parseFloat(lat).toFixed(8), lng: parseFloat(lng).toFixed(8) });

                // bcode를 legalCode로 사용
                setLegalCode(bcode);

            } else {
                console.warn("No coordinates found for the given address.");
                setLatLng({ lat: null, lng: null });
                setLegalCode(null);  // legalCode도 초기화
            }

        } catch (error) {
            console.error("Error fetching coordinates or legalCode:", error);
            setLegalCode(null);  // legalCode 초기화
        }
    };


    const handlePriceNegotiationToggle = () => {
        const newNegotiationState = !isPriceNegotiable;
        setIsPriceNegotiable(newNegotiationState);
        setIsInputDisabled(newNegotiationState);

        if (newNegotiationState) {
            setPrice("");  // 가격협의가 활성화되면 가격 입력 필드를 비우기
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value.replace(/,/g, '');
        if (!isNaN(value) && value.length <= 9) {
            setPrice(new Intl.NumberFormat().format(value));
        }
    };

    const handleTitleChange = (e) => e.target.value.length <= 30 && setTitle(e.target.value);
    const handleContentChange = (e) => e.target.value.length <= 2000 && setContent(e.target.value);
    const { activeTab, selectedOption, serviceOption } = location.state || {};
    const parseMainTab = (tab) => tab.match(/^[^()]+/)[0];

    const [emptyPopupContent, setEmptyPopupContent] = useState('');
    const [emptyPopupVisible, setEmptyPopupVisible] = useState(false);

    const openEmptyPopup = (popupContent) => {
        setEmptyPopupContent(popupContent);
        setEmptyPopupVisible(true);
    };

    // 변경된 handleSubmit 함수 내의 코드
    const handleSubmit = async () => {
        const tradeType = serviceOption === 'ReqSelect' ? 1 : 2;
        const costValue = isPriceNegotiable ? 0 : parseInt(price.replace(/,/g, ''), 10);
        const isCostNegotiable = isPriceNegotiable;
        let inputEmpty = '';

        // 분기 처리
        let latLngValue, finalLegalCode, road, jibun;
        if (displayAddress) {
            // 간편 주소 선택한 경우
            latLngValue = latLng;  // 이미 선택된 latLng 값 사용
            finalLegalCode = legalCode; // 이미 선택된 legalCode 사용

            // 간편 주소의 경우 road와 jibun 동일하게 설정
            road = displayAddress;
            jibun = displayAddress;
        } else if (searchAddress) {
            // 주소 검색을 통해 선택한 경우
            latLngValue = latLng;  // 이미 선택된 latLng 값 사용
            // finalLegalCode = legalCode; // 이미 선택된 legalCode 사용

            let parsedAddress = searchAddress;
            if (parsedAddress.startsWith("전남")) {
                parsedAddress = parsedAddress.replace("전남", "전라남도");
            }
            const jibunParts = parsedAddress.split(' ');
            jibun = jibunParts.slice(0, 4).join(' ');  // 첫 4개 부분만 사용 (전라남도, 함평군, 함평읍, 리)
            road = searchAddress;
        } else {
            inputEmpty += '주소';
        }
        if (!title) {
            if (inputEmpty.length !== 0) {
                inputEmpty += ', ';
            }
            inputEmpty += '제목';
        }
        if (!costValue && !isCostNegotiable) {
            if (inputEmpty.length !== 0) {
                inputEmpty += ', ';
            }
            inputEmpty += '가격';
        }
        // if (!content) {
        //     if (inputEmpty.isNotEmpty) {
        //         inputEmpty += ', ';
        //     }
        //     inputEmpty += '내용';
        // }
        if (inputEmpty.length !== 0) {
            openEmptyPopup(inputEmpty);
            return;
        }

        let categoryId;
        switch (selectedOption) {
            case '트랙터': categoryId = 6; break;
            case '경운기': categoryId = 7; break;
            case '이앙기': categoryId = 8; break;
            case '관리기': categoryId = 9; break;
            case '콤바인': categoryId = 10; break;
            case '드론': categoryId = 11; break;
            case '기타 농기계': categoryId = 12; break;
            case '논': categoryId = 13; break;
            case '밭': categoryId = 14; break;
            case '과수원': categoryId = 15; break;
            case '하우스재배': categoryId = 16; break;
            case '축사': categoryId = 17; break;
            case '농업 창고': categoryId = 18; break;
            case '기타농지': categoryId = 19; break;
            case '농작업 인력': categoryId = 20; break;
            case '행사 인력': categoryId = 21; break;
            case '기타 인력': categoryId = 22; break;
            case '벼/맥류': categoryId = 23; break;
            case '양파': categoryId = 24; break;
            case '고추': categoryId = 25; break;
            case '단호박': categoryId = 26; break;
            case '기타 농축산물': categoryId = 27; break;
            case '기타거래': categoryId = 5; break;
            default: categoryId = 1;
        }

        // 이미지 파일 처리
        const addImageList = await Promise.all(files.map(file => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    try {
                        const base64data = reader.result.split(',')[1]; // base64 인코딩 데이터
                        const fileName = file.name.split('.').slice(0, -1).join('.'); // 파일 이름
                        const fileExtension = file.name.split('.').pop(); // 파일 확장자
                        resolve({
                            encodedFile: base64data,
                            fileName,
                            fileExtension,
                        });
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);  // 파일을 읽어옵니다.
            });
        }));

        const requestBody = {
            tradeType,
            categoryId,
            title,
            desc: content,
            road,  // 도로명 주소
            jibun, // 지번 주소
            lat: latLngValue.lat,  // 좌표값을 사용
            lng: latLngValue.lng,
            paymentType: 1,
            cost: costValue,
            isCostNegotiable,
            addImageList,  // 이미지 리스트 추가
            villageHallId,
        };


        try {
            const response = await fetch(`${wasUrl}trade`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authToken}`
                },
                body: JSON.stringify(requestBody),
            });

            if (response.ok) {
                const responseData = await response.json();
                navigate(`/tradedeTailMain?tradeId=${responseData.id}`);
            } else {
                console.error("Failed to register trade. Status code:", response.status);
            }
        } catch (error) {
            console.error("Error occurred while registering trade:", error);
        }
    };

    return (
        <section id="WritingPage" className="container">
            <Header />
            <div className="content content4 bottom scrollbar bc-white">
                <BackHeader
                    title='글 작성하기'
                />
                <div className="inner bc-white p-relative pt-10 pb-10 d-flex a-center j-center">
                    <img src={serviceOption === 'ReqSelect' ? reqImg : resImg}
                        className="reqresImg"
                        alt="serviceImg"
                        style={{ left: 0 }}
                    />
                    <span className="fs-34 fw-7 d-block ta-center">
                        {`${writeState === '신규' ? activeTab : parseMainTab(activeTab)}`}({selectedOption})
                    </span>
                </div>
                <div className="bc-lightGray10 pt-10">
                    <div className="writeElementsBox w-100 bc-white">
                        <div className="inner">
                            {writeState === '수정' && (
                                <div className="selectCategory d-flex f-column a-start j-center pt-28 pb-10">
                                    <span className="categoryHeader fs-26 fw-7 fc-green1 mb-24">카테고리</span>
                                    <div className="radioBtnBar d-flex f-row a-center j-left mb-16">
                                        <SelectedRadio style={{ width: 28, height: 28 }} />
                                        <span className="fs-22 fw-6 fc-darkGray8 pl-16">{parseMainTab(activeTab)}</span>
                                        <span className="fs-18 fw-6 fc-mediumGray2 pl-4">{'(대카테고리는 수정이 불가합니다)'}</span>
                                    </div>
                                    <button className="openCategoryBtn d-flex a-center j-between br-5 ba-1-green1"
                                        style={{ width: '100%', height: 60 }}
                                        onClick={handleOpenTradePopup}>
                                        <span className="fs-22 fw-5 fc-black2 pl-25">{selectedOption}</span>
                                        <span className="fs-22 fw-5 fc-darkGray21 pr-25">▼</span>
                                    </button>
                                </div>
                            )}
                            <div className="spaceBlock pt-28" />
                            {/* <CommonInputBox
                                id='title-input'
                                title='제목'
                                titleClass='fs-26 fw-7 fc-green1'
                                hintMessage='거래 물품 종류나 요구사항을 간단히 적어주세요.'
                                placeholder='30자 이내로 작성해주세요.'
                                maxLength={30}
                                value={title}
                                setValue={setTitle}
                            /> */}
                            <div className="fs-26 fw-7 fc-green1">제목</div>
                            <div className="fs-18 fw-6 mt-15 mb-10 fc-mediumGray2">거래 물품 종류나 요구사항을 간단히 적어주세요.</div>
                            <textarea
                                className="title-input w-100 br-6 fs-24 bc-white ellipsis-2"
                                placeholder="30자 이내로 작성해주세요."
                                value={title}
                                onChange={handleTitleChange}
                            />
                            <div className="fs-26 fw-7 fc-green1 pt-40 mb-15">가격제안</div>
                            <div className="price-input-container mb-20">
                                <input
                                    type="text"
                                    className="price-input fs-22 fw-4 fc-black2 pr-10"
                                    placeholder={isInputDisabled ? "입력 불가" : "숫자만 입력"}
                                    disabled={isInputDisabled}
                                    value={price}
                                    onChange={handleInputChange}
                                    style={{ textAlign: 'right' }}  // 텍스트 정렬 스타일 추가
                                />
                                <span className="fw-5 fs-22 pl-10">원</span>
                                <div className="mt-20 d-flex a-center" onClick={handlePriceNegotiationToggle} style={{ cursor: 'pointer' }}>
                                    <span className="pr-10">
                                        <img src={isPriceNegotiable ? circleCheckButtonFill : circleCheckButton} alt="o" />
                                    </span>
                                    <span className="fs-22 fw-6">가격협의</span>
                                    <span className="fs-22 fw-4">할게요</span>
                                </div>
                            </div>
                            <div className="fs-26 fw-7 fc-green1 pt-20 pb-25">
                                작업 및 대여위치
                            </div>
                            <div
                                className="br-6 pt-20 pb-20 mb-25"
                                style={{ border: '1px solid rgba(191, 191, 191, 1)', display: 'flex', alignItems: 'center' }}
                                onClick={handleSimpleAddressPopupToggle}  // 간편 주소 선택 팝업 열기
                            >
                                <img className="radioBtnImg pl-15" src={displayAddress ? radioFill : radio} alt="라디오" style={{ marginRight: '10px' }} />
                                <span className="pl-15">
                                    {displayAddress ? (
                                        <>
                                            <div className="fw-4 fs-20 pb-10 pt-10">{displayAddress}</div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="fw-6 fs-22">간편 주소 선택</div>
                                            <div className="fw-400 fs-20">간편하게 읍,면,리를 선택해보세요</div>
                                        </>
                                    )}
                                </span>
                                <img
                                    src={RightArrowIcon}
                                    style={{ marginLeft: 'auto', width: '35px', height: '35px' }}
                                    alt="Right Arrow"
                                />
                            </div>

                            <div
                                className="br-6 pt-20 pb-20"
                                style={{ border: '1px solid rgba(191, 191, 191, 1)', display: 'flex', alignItems: 'center' }}
                                onClick={handleOpenPopup}  // 다음 주소 검색 팝업 열기
                            >
                                <img className="radioBtnImg pl-15" src={searchAddress ? radioFill : radio} alt="라디오" style={{ marginRight: '10px' }} />
                                <span className="pl-15">
                                    {searchAddress ? (
                                        <>
                                            <div className="fw-4 fs-20 pb-10 pt-10">{searchAddress}</div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="fw-6 fs-22">주소 찾기</div>
                                            <div className="fw-400 fs-20">주소 검색으로 위치를 검색해보세요</div>
                                        </>
                                    )}
                                </span>
                                <img
                                    src={RightArrowIcon}
                                    style={{ marginLeft: 'auto', width: '35px', height: '35px' }}
                                    alt="Right Arrow"
                                />
                            </div>

                            <div className="fs-26 fw-7 pt-40 pb-15">
                                <span className="fc-green1">사진</span><span>(선택사항)</span>
                            </div>
                            <ImageUploader images={images} setImages={setImages} setFiles={setFiles} />
                            <div className="fs-26 fw-7 pt-40 pb-15">
                                <span className="fc-green1">상세 설명</span><span>(선택사항)</span>
                            </div>
                            <div className="fs-18 fw-6 fc-mediumGray2 pb-10">
                                농기계 판매나 작업 요청에 필요한 자세한 설명을 입력해주세요.
                            </div>
                            <textarea
                                className="content-input mb-40 fs-22 fw-4 fc-black2"
                                placeholder="2000자  이내로 작성해주세요."
                                value={content}
                                onChange={handleContentChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="inner pt-20">
                <CommonBarBtn
                    id='writeBtn'
                    active={true}
                    text='등록'
                    activeText='등록'
                />
                {/* <CommonBarBtn2
                    id='writeBtn'
                    active={true}
                    text='등록'
                    activeText='등록'
                    event={handleSubmit}
                /> */}
            </div>
            <AddressSelector
                isSimpleAddressPopupOpen={isSimpleAddressPopupOpen}
                setIsSimpleAddressPopupOpen={setIsSimpleAddressPopupOpen}
                setDisplayAddress={setDisplayAddress}
                setSearchAddress={setSearchAddress}
                setSelectedCity={setSelectedCity}
                setSelectedSubCity={setSelectedSubCity}
                setLegalCode={setLegalCode}
                setLatLng={setLatLng}
                setVillageHallId={setVillageHallId}
            />
            <CommonPopup2
                id='emptyPopup'
                title={`
                    <span class='fc-red1'>${emptyPopupContent}</span>
                    <span class='fc-black1'> 항목은 필수로 입력해 주세요.</span>
                `}
                btnText1='확인'
                btnEvent1={false}
                status={emptyPopupVisible}
                setStatus={setEmptyPopupVisible}
            />
            {isTradePopupOpen && (
                <TradeDropdownPopup list={['농기구전체', '트랙터', '이앙기']} type={parseMainTab(activeTab)} />
            )}
            {isPopupOpen && (
                <div className="customPopupContainer" style={{ width: 'calc(600 * 100vw / 600)', backgroundColor: 'white' }}>
                    <section className="container">
                        <Header />
                        <div className="content content2 scrollbar">
                            <BackHeaderAddress title={'주소 검색'} setValue={() => setIsPopupOpen(false)} />
                            <div>
                                <DaumPostcode
                                    style={{ width: 'calc(600 * 100vw / 600)', minWidth: 360, maxWidth: 600, height: '100vh' }}
                                    onComplete={(data) => {
                                        handlePostCode(data);
                                        handleClosePopup(); // 주소 선택 후 모달 닫기
                                    }}
                                />
                            </div>
                        </div>
                    </section>
                </div>
            )}
        </section>
    );
}

export default WritingPage;
