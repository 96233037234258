import axios from "axios";
import { wasUrl } from "utils/commonUtil";

export const sendTel = async (data) => {
    await axios.post(`${wasUrl}cert/sms`, data);
}

export const checkTel = async (data) => {
    const response = await axios.post(`${wasUrl}join/check/tel`, data);
    return response;
};


export const verifyTelCode = async (data) => {
    const response = await axios.post(`${wasUrl}cert/sms/check`, data);
    return response;
}

export const setLogin = async (data,JoinToken) => {
    const response = await axios.post(
        `${wasUrl}login/member`,
        data,
        {
            headers: {
                'Authorization': `Bearer ${JoinToken}` 
            },
            timeout: 120000
        }
    );
    return response; 
}

export const setLoginToken = async (authToken) => {
    const response = await axios.post(
        `${wasUrl}login/token`, {},
        {
            headers: {
                'Authorization': `Bearer ${authToken}` 
            }
        }

    );
    return response;
}

export const reToken = async (authToken, refreshToken) => {
    const response = await axios.post(
        `${wasUrl}login/token/refresh`,
        {
            headers: {
                'Authorization': `${authToken}` ,
                'X-Refresh-Token': `${refreshToken}`
            }
        }
    );
    return response;
}