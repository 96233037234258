import React, { useState, useEffect } from "react";
import { pageLoadingEvent } from "utils/commonUtil";
import Header from "components/header/Header";
import { ReactComponent as MapPinIcon } from "assets/icon/mapPin.svg";
import BackHeader from "components/header/BackHeader";
import { getWeatherInfo } from "api/weather";
import { weatherDisplay } from "data/weatherData";
import { ReactComponent as WeatherDarkgray } from "assets/icon/weatherDarkgray.svg";

const Weather = () => {
    const [weatherInfo, setWeatherInfo] = useState(null);
    const [yesterdayInfo, setYesterdayInfo] = useState(null);
    const [todayInfo, setTodayInfo] = useState(null);
    const [weekdayInfos, setWeekdayInfos] = useState(null);

    const formatDateToMMDD = (date) => {
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${month}.${day}`;
    };

    const getWeekRange = (currentDate) => {
        const dates = [];

        for (let i = -1; i <= 6; i++) {
            const date = new Date(currentDate);
            date.setDate(currentDate.getDate() + i);
            dates.push(formatDateToMMDD(date));
        }

        return dates;
    };

    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    const dateTexts = getWeekRange(currentDate);

    const dataSetting = async () => {
        const data = sessionStorage.getItem(`${currentHour}_Weather`);
        if (data && data.length !== 0) {
            const parsedData = JSON.parse(data);  // 저장된 문자열을 객체로 변환
            setWeatherInfo(parsedData);
            setYesterdayInfo(parsedData.yesterdayWeather);
            setTodayInfo(parsedData.todayWeather);
            setWeekdayInfos(parsedData.weeklyWeathers);
        } else {
            const tempData = await getWeatherInfo();
            console.log(tempData);
            console.log(tempData.yesterdayWeather);
            console.log(tempData.todayWeather);
            console.log(tempData.weeklyWeathers);

            // JSON 데이터를 문자열로 변환하여 세션 스토리지에 저장
            if (!(typeof tempData.yesterdayWeather === 'undefined' || typeof tempData.todayWeather === 'undefined' || typeof tempData.weeklyWeathers === 'undefined')) {
                sessionStorage.setItem(`${currentHour}_Weather`, JSON.stringify(tempData));
                setWeatherInfo(tempData);
            }

            setYesterdayInfo(tempData.yesterdayWeather);
            setTodayInfo(tempData.todayWeather);
            setWeekdayInfos(tempData.weeklyWeathers);
        }
    };

    useEffect(() => {
        pageLoadingEvent();
        // 날씨데이터 로딩 - 세션 or api
        dataSetting();
    }, []);

    return (
        <section id="Weather" className="container">
            <Header />
            <div className="content homeContent scrollbar">
                <BackHeader
                    title='함평군 날씨'
                />
                <div className="inner">
                    <div className="d-flex a-center j-left mt-20">
                        <MapPinIcon width={17} height={21} style={{ '--path-color': '#51CFB2' }} />
                        <span className="fs-24 fw-5 ls-2 ml-10 fc-green1">함평군</span>
                    </div>
                </div>
                <div className="inner">
                    {(typeof yesterdayInfo !== 'undefined' || typeof todayInfo !== 'undefined' || typeof weekdayInfos !== 'undefined')
                        ? <article className="br-9 pt-24">
                            {/* 어제 */}
                            {yesterdayInfo && ( // 데이터가 로드되었는지 확인 후 렌더링
                                <div className="white-container2 d-flex a-center j-between mb-24"
                                    style={{ width: '100%' }}>
                                    <div className="left-date-info d-flex f-column a-start j-center ml-30">
                                        <span className="fs-26 fw-6 fc-mediumGray">어제</span>
                                        <span className="fs-24 fw-4 fc-black2">{dateTexts[0]}</span>
                                    </div>
                                    <div className="right-date-info d-flex f-row a-center j-right mr-26"
                                        style={{ width: '60%' }}>
                                        <div className="rainfall-info mr-18">
                                            <span className="fs-18 fw-4 fc-mediumGray">누적 강수량</span>
                                            <span className="fw-5 fs-24 fc-black2">{`${yesterdayInfo.rainAmount}mm`}</span>
                                        </div>
                                        <div className="temperature-info d-flex f-column pl-18 bl-1-mediumGray25">
                                            <div className="fc-mediumGray fs-18 fw-4">
                                                <span className="fc-mediumGray fs-18 fw-4">
                                                    {'최저기온 '}
                                                </span>
                                                <span className="fs-20 fw-4 fc-black2">
                                                    {`${yesterdayInfo.minCelsius}°`}
                                                </span>
                                            </div>
                                            <div className="fc-mediumGray fs-18 fw-4">
                                                <span className="fc-mediumGray fs-18 fw-4">
                                                    {'최고기온 '}
                                                </span>
                                                <span className="fs-20 fw-4 fc-black2">
                                                    {`${yesterdayInfo.maxCelsius}°`}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* 오늘 */}
                            {todayInfo && ( // 데이터가 로드되었는지 확인 후 렌더링
                                <div className="white-container3 d-flex a-center j-between mb-20">
                                    <div className="left-date-info d-flex f-row a-center j-between ml-20 pr-15" style={{ width: '55%', height: 109 }}>
                                        <img src={weatherDisplay(todayInfo.weatherType, currentHour).icon}
                                            alt="currentWeather"
                                            style={{ width: '43.47%', aspectRatio: '1', objectFit: "contain" }} />
                                        <div className="d-flex f-column a-start j-center ml-21"
                                            style={{ height: '100%' }}>
                                            <span className="fs-26 fw-6 fc-red1">오늘</span>
                                            <span className="fs-24 fw-4">{dateTexts[1]}</span>
                                            <span className="fs-36 fw-6">{`${todayInfo.currentCelsius}°`}</span>
                                        </div>
                                    </div>
                                    <div className="right-date-info d-flex f-column a-center j-center pl-15 mr-20 bl-1-mediumGray25"
                                        style={{ width: '45%', height: 112 }}>
                                        <div className="d-flex f-row a-center j-between" style={{ width: '100%', height: 33 }}>
                                            <span className="fc-mediumGray fs-24 fw-4">{'강수확률'}</span>
                                            <span className="fs-28 fw-5 fc-black2">{`${todayInfo.rainProbability}%`}</span>
                                        </div>
                                        <div className="d-flex f-row a-center j-between mb-8 mt-8" style={{ width: '100%', height: 33 }}>
                                            <span className="fc-mediumGray fs-24 fw-4">{'최저기온'}</span>
                                            <span className="fs-28 fw-5 fc-black2">{`${todayInfo.minCelsius}°`}</span>
                                        </div>
                                        <div className="d-flex f-row a-center j-between" style={{ width: '100%', height: 33 }}>
                                            <span className="fc-mediumGray fs-24 fw-4">{'최고기온'}</span>
                                            <span className="fs-28 fw-5 fc-black2">{`${todayInfo.maxCelsius}°`}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* 오늘이후 6일 */}
                            {weekdayInfos && weekdayInfos.map((info, index) => { // 데이터가 로드되었는지 확인 후 렌더링
                                const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];

                                const currentDate = new Date();
                                currentDate.setDate(currentDate.getDate() + index + 1);
                                const dayOfWeek = daysOfWeek[currentDate.getDay()];

                                return (
                                    <div key={index} className="white-container2 d-flex a-center j-between mb-22">
                                        <div className="left-date-info d-flex f-row a-start j-between ml-30 mr-20"
                                            style={{ width: '56%' }}>
                                            <div className="date-info d-flex f-column a-start j-center">
                                                <span className='fs-26 fw-6 fc-black2'>
                                                    {dayOfWeek}
                                                </span>
                                                <span className="fs-24 fw-4">{dateTexts[2 + index]}</span>
                                            </div>
                                            <div className="rainPercent-info d-flex f-column a-start j-center">
                                                <div className="fc-mediumGray fs-24 fw-4">강수확률 </div>
                                                <div className="fw-5 fs-28 fc-black2">{`${info.rainProbability}%`}</div>
                                            </div>
                                        </div>
                                        <div className="right-date-info d-flex f-row a-center j-center mr-26 bl-1-mediumGray25"
                                            style={{ width: '44%' }}>
                                            <div className="temp-info d-flex f-column a-center j-center"
                                                style={{ width: '100%' }}>
                                                <div className="lowTemp d-flex f-row a-center j-center"
                                                    style={{ width: '100%' }}>
                                                    <div className="fs-24 fw-4 fc-mediumGray">최저기온 </div>
                                                    <div className="fw-5 fs-28 fc-black2 ml-8">{`${info.minCelsius}°`}</div>
                                                </div>
                                                <div className="highTemp d-flex f-row a-center j-center"
                                                    style={{ width: '100%' }}>
                                                    <div className="fs-24 fw-4 fc-mediumGray">최고기온 </div>
                                                    <div className="fw-5 fs-28 fc-black2 ml-8">{`${info.maxCelsius}°`}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </article>
                        : <div className="bc-white br-9 mb-40 d-flex f-column a-center j-center w-100"
                            style={{ height: 177 }}
                        >
                            <WeatherDarkgray style={{ width: 44, height: 44 }} />
                            <span className="fs-22 fw-5 fc-mediumGray2">기상청의 날씨 정보를 불러오지 못했습니다.</span>
                            <span className="fs-22 fw-5 fc-mediumGray2">불편을 드려 죄송합니다.</span>
                        </div>
                    }
                </div>
            </div>
        </section>
    );
};

export default Weather;