import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { pageLoadingEvent } from "utils/commonUtil";
import mailIcon from 'assets/icon/mail.svg';
import resendBtn from 'assets/image/button/resendBtn.png';
import resendBtn2 from 'assets/image/button/resendBtn2.svg';
import LoginTopBox from "../../components/login/LoginTopBox";
import LoginInputBox from "../../components/login/LoginInputBox";
import { sendTel, checkTel, verifyTelCode, setLogin, setLoginToken, reToken } from 'api/LoginApi';
import CommonPopup from 'components/popup/CommonPopup';
import CommonBarBtn from 'components/button/CommonBarBtn';
import { useCookies } from 'react-cookie';
import { getMyInfoApi } from 'api/myInfoApi';

const ReqVerNum = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["authToken", "refreshToken", "joinToken"]);
  const [seconds, setSeconds] = useState(120);
  const [isActive, setIsActive] = useState(true);
  const [isPopupOpen, setIsDuplicatePopupOpen] = useState(false);
  const [isNoneVerPopupOpen, setIsNoneVerPopupOpen] = useState(false);
  const [isTimeOutPopupOpen, setIsTimeOutPopupOpen] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");

  const queryParams = new URLSearchParams(location.search);
  const phoneNumber = queryParams.get("phone");

  useEffect(() => {
    pageLoadingEvent();

    if (!phoneNumber) {
      console.error("Phone number is null or undefined");
    }

    const confirmBtn = document.querySelector('#notRegisterPopup .twoBtn');
    confirmBtn.addEventListener('click', moveTerems);

    const nextBtn = document.getElementById('nextBtn');
    const handleKeyDown = (e) => {
      if (e.key === 'Enter' || e.key === 'Go') {
        handleCompleteClick();  // Enter 또는 Go 키를 눌렀을 때 함수 호출
      }
    };

    // 클릭 및 키보드 이벤트 리스너 추가
    nextBtn.addEventListener('click', handleCompleteClick);
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      confirmBtn.removeEventListener('click', moveTerems);
      nextBtn.removeEventListener('click', handleCompleteClick);
      window.removeEventListener('keydown', handleKeyDown); // 키다운 이벤트 리스너 제거
    };
  }, [phoneNumber]);

  useEffect(() => {
    let interval = null;

    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      setIsActive(false);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const moveTerems = () => {
    navigate(`/terms?phone=${phoneNumber}&ver=ver`);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };


  const resendCode = () => {
    setSeconds(120);
    document.getElementById("code").value = "";
    setVerificationCode("");
    setIsActive(true);
    sendVerificationCode();
  };

  const sendVerificationCode = async () => {
    try {
      const data = { tel: phoneNumber };
      await sendTel(data);
    } catch (error) {
      console.error("SMS 전송 중 오류 발생:", error);
    }
  };

  const checkPhoneNumber = async () => {
    try {
      const data = { tel: phoneNumber };
      const response = await checkTel(data);

      if (response.data.checkResult === false) {
        return true; // 중복 없음, 계속 진행
      } else if (response.data.checkResult === true) {
        setIsDuplicatePopupOpen(true); // 중복된 번호 팝업
        return false;
      } else {
        throw new Error("Invalid response data structure");
      }
    } catch (error) {
      console.error("전화번호 확인 중 오류 발생:", error.message);
      return false;
    }
  };


  const checkVerificationCode = async () => {
    const code = document.getElementById("code").value;
    try {
      const data = { tel: phoneNumber, certNo: code };
      const response = await verifyTelCode(data);

      if (response.status === 200) {
        const joinToken = response.data.joinToken;

        // joinToken을 쿠키에 저장
        setCookie('joinToken', joinToken, { maxAge: 60 * 60, path: "/", secure: false, sameSite: "strict" });

        return joinToken;
      } else {
        setIsNoneVerPopupOpen(true);
        return false;
      }
    } catch (error) {
      console.error("인증 코드 확인 중 오류 발생:", error);
      setIsNoneVerPopupOpen(true);
      return false;
    }
  };

  const loginWithPhoneNumber = async (joinToken) => {
    const authToken = cookies.authToken;
    const refreshToken = cookies.refreshToken;
    console.log(`authToken:${authToken}`);
    console.log(`refreshToken:${refreshToken}`);
    if (!authToken) {
      await loginUsingPhone(joinToken);
    } else {
      try {
        console.log(`authToken:${authToken}`);
        console.log(`refreshToken:${refreshToken}`);
        const response = await setLoginToken(authToken);
        if (response.status === 200) {
          localStorage.setItem('userId', response.data.userId);
          const getUserName = await getMyInfoApi(response.data.userId, response.data.authToken);
          localStorage.setItem("nickName", getUserName.name);
          navigate('/home');
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          await refreshAuthToken(authToken, refreshToken);
        } else {
          console.error('Unexpected error occurred during token-based login:', error);
        }
      }
    }
  };

  const loginUsingPhone = async (joinToken) => {
    try {
      const loginData = {
        loginId: phoneNumber,
      };
      console.log(`joinToken:${joinToken}`);
      console.log(`loginId:${phoneNumber}`);
      const response = await setLogin(loginData, joinToken);

      if (response.status === 200) {
        const data = response.data;

        // authToken과 refreshToken을 쿠키에 저장
        setCookie('authToken', data.authToken, { maxAge: 3 * 60 * 60, path: "/", secure: false, sameSite: "strict" });
        setCookie('refreshToken', data.refreshToken, { maxAge: 30 * 24 * 60 * 60, path: "/", secure: false, sameSite: "strict" });

        localStorage.setItem('userId', data.userId);
        const getUserName = await getMyInfoApi(response.data.userId, response.data.authToken);
        localStorage.setItem("nickName", getUserName.name);

        navigate('/home');
      } else {
        console.error('Failed to log in with the existing phone number.');
      }
    } catch (error) {
      if (error.response) {
        // 서버에서 반환한 에러 응답이 있는 경우
        console.error('Error occurred while logging in with the phone number:');
        console.error(`Status: ${error.response.status}`);
        console.error(`Message: ${error.response.data.message}`);
        console.error(`Details: ${JSON.stringify(error.response.data)}`);
      } else if (error.request) {
        // 요청이 서버에 전달되었으나 응답이 없는 경우
        console.error('Error occurred while logging in with the phone number. No response received from server.');
        console.error(`Request Details: ${JSON.stringify(error.request)}`);
      } else {
        // 기타 에러
        console.error('Error occurred while logging in with the phone number:', error.message);
      }
    }
  };

  const refreshAuthToken = async (authToken, refreshToken) => {
    try {
      const response = await reToken(authToken, refreshToken);
      if (response.status === 200) {
        const data = response.data;
        setCookie('authToken', data.authToken, { maxAge: 3 * 60 * 60, path: "/", secure: false, sameSite: "strict" });
        setCookie('refreshToken', data.refreshToken, { maxAge: 30 * 24 * 60 * 60, path: "/", secure: false, sameSite: "strict" });
        await loginWithPhoneNumber(); // 새로운 토큰으로 다시 로그인 시도
      } else {
        console.error('Failed to refresh auth token.');
      }
    } catch (error) {
      console.error('Error occurred while refreshing auth token:', error);
    }
  };

  const handleCompleteClick = async () => {
    if (seconds === 0) {
      setIsTimeOutPopupOpen(true);
      setVerificationCode('');
      return;
    }

    const joinToken = await checkVerificationCode();

    if (joinToken) {
      const isPhoneNumberValid = await checkPhoneNumber();

      if (isPhoneNumberValid) {
        await loginWithPhoneNumber(joinToken);
      }
    }
  };

  return (
    <section id="SignUpVerNum" className="container">
      <div className="content content3 scrollbar">
        <div className="inner">
          <LoginTopBox
            image={mailIcon}
            text1="를"
            text2="입력해주세요"
            greenText1="인증번호 4자리"
            greenText2=""
          />
          <div className="d-flex active j-between">
            <div className="d-flex a-center j-between loginInputBox codeBox">
              <LoginInputBox
                id="code"
                maxLength={4}
                placeholder="예시) 1234"
                setValue={setVerificationCode}
                type="number"
                autoComplete={true}
              />
              <span className="timerText fc-red1 fs-24 fw-6 mr-29">{formatTime(seconds)}</span>
            </div>
            <button className="resendBtn" onClick={resendCode} disabled={isActive}>
              <img src={isActive ? resendBtn : resendBtn2} alt="재전송버튼" />
            </button>
          </div>
        </div>
      </div>
      <div className='inner'>
        <CommonBarBtn
          id='nextBtn'
          active={verificationCode}
          text='완료'
          activeText='완료'
        />
      </div>
      <CommonPopup
        id='notRegisterPopup'
        btnType='two'
        title1=''
        title2='가입되지 않은 번호'
        title3='에요.'
        text='아직 가입되지 않은 번호입니다. 회원가입을 진행해주세요.'
        btnText1='닫기'
        btnText2='회원가입'
        status={isPopupOpen}
        setStatus={setIsDuplicatePopupOpen}
        btnEvent1={false}
      />
      <CommonPopup
        id='veriNumIncorrectPopup'
        title1=''
        title2='인증번호'
        title3='가 불일치해요.'
        text='전송된 인증문자를 확인 후 다시 입력해주세요.'
        btnText1='확인'
        status={isNoneVerPopupOpen}
        setStatus={setIsNoneVerPopupOpen}
        btnEvent1={false}
      />
      <CommonPopup
        id='timeoutPopup'
        title1=''
        title2='입력 시간'
        title3='이 초과되었어요.'
        text='인증번호 입력시간이 초과되어 새로운 인증 문자를 전송 후 입력해 주세요.'
        btnText1='확인'
        status={isTimeOutPopupOpen}
        setStatus={setIsTimeOutPopupOpen}
        btnEvent1={false}
      />
    </section>
  );
};

export default ReqVerNum;
