import React from "react";
import {Link, useNavigate} from "react-router-dom";

const HomePopup = () => {
  const navigate = useNavigate();

  return (
    <div
      id="homePopup"
      className={`commonPopup popupContainer d-flex a-center j-center d-none`}
    >
      <div className="popupBody bc-white scrollbar">
        <div className="inner">
          <div className="textArea ta-center">
            <div className="popupText">
              <p className="fs-28 fw-7 popupTitle">
                함평군 농기계 임대사업소<br/>
                <span className="fc-green1">회원가입</span>이 필요해요
              </p>
              <p className=" fs-22 fw-5">일치하는 계정이 없습니다. 회원가입 또는 농기계 둘러보기를 이용해보세요.</p>
            </div>
          </div>
          <div className="btnArea d-flex a-center j-between fs-24 fw-7 fc-white">
            <button
              className="oneBtn fc-green1 half lineBtn"
              onClick={() => {
                navigate("/center");
              }}
            >
              둘러보기
            </button>
            <Link
              className="d-block twoBtn half bc-green1"
              to="https://www.hampyeong.go.kr/aml/mamber_write_step0.jsp"
              target="_blank"
            >
              회원가입
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePopup;