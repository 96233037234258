import React from "react";

const CommonPopup = (
  { id, image, title1, title2, title3, text, btnType, btnText1, btnText2, status, setStatus, btnEvent1, btnEvent2 }
) => {
  const defaultBtnType = btnType || "one";
  const closeEvent = () => {
    if (btnEvent1) {
      btnEvent1();
    } else {
      setStatus(false); // 없으면 팝업 닫기
    }
  }

  return (
    <div
      id={id}
      className={`commonPopup popupContainer d-flex a-center j-center ${status ? "d-block" : "d-none"}`}
    >
      <div className="popupBody bc-white scrollbar">
        <div className="inner">
          <div className="textArea ta-center">
            {image &&
              <img
                className="popupImg mb-30"
                src={image}
                alt="팝업 아이콘"
                loading="lazy"
              />
            }
            <p className="fs-28 fw-7 popupTitle">
              {title1}
              <span className="fc-green1">{title2}</span>
              {title3}
            </p>
            <p
              className="popupText fs-22 fw-5"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </div>
          <div className="btnArea d-flex a-center j-between fs-24 fw-7 fc-white">
            <button
              className={`oneBtn ${defaultBtnType === "one" ? "bc-green1" : "bc-mediumGray9 half"}`}
              onClick={closeEvent}
            >
              {btnText1}
            </button>
            {defaultBtnType === "two" &&
              <button
                className="twoBtn half bc-green1"
                onClick={() => btnEvent2()}
              >
                {btnText2}
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonPopup;