import React, { useState, useEffect, useRef } from "react";
import { cityData } from "data/cityData.js";
import { ReactComponent as MapPinIcon } from "assets/icon/map-pinDark.svg";
import { ReactComponent as CloseButton } from "assets/icon/xButton.svg";
import LoginButton from "components/footer/LoginButton";
import underArrow from 'assets/marker/under-arrow.svg';
import { wasUrl } from "utils/commonUtil";

const AddressSelector = ({
  isSimpleAddressPopupOpen,
  setIsSimpleAddressPopupOpen,
  setDisplayAddress,
  setSearchAddress,
  setSelectedCity,
  setSelectedSubCity,
  setLegalCode,
  setLatLng,
  setVillageHallId
}) => {
  const [localSelectedCity, setLocalSelectedCity] = useState("");
  const [localSelectedSubCity, setLocalSelectedSubCity] = useState("");
  const [villageCenters, setVillageCenters] = useState([]);
  const [selectedVillageCenter, setSelectedVillageCenter] = useState("");

  const [isCityDropdownOpen, setIsCityDropdownOpen] = useState(false);
  const [isSubCityDropdownOpen, setIsSubCityDropdownOpen] = useState(false);
  const [isVillageCenterDropdownOpen, setIsVillageCenterDropdownOpen] = useState(false);

  // Refs for custom select containers
  const cityContainerRef = useRef(null);
  const subCityContainerRef = useRef(null);
  const cityOptionsRef = useRef(null);
  const subCityOptionsRef = useRef(null);
  const villageCenterContainerRef = useRef(null);
  const villageCenterOptionsRef = useRef(null);
  const resetDropdowns = () => {
    setLocalSelectedCity("");
    setLocalSelectedSubCity("");
    setSelectedVillageCenter("");
    setVillageCenters([]);
    setIsCityDropdownOpen(false);
    setIsSubCityDropdownOpen(false);
    setIsVillageCenterDropdownOpen(false);
  };
  useEffect(() => {
    if (isSimpleAddressPopupOpen) {
      resetDropdowns();  // 팝업이 열릴 때 상태 초기화
    }
  }, [isSimpleAddressPopupOpen]);
  useEffect(() => {
    if (localSelectedSubCity) {
      // legalCode 대신 sido, sigungu, dong, ri를 사용하여 villageCenters를 가져옴
      fetchVillageCenters("전라남도", "함평군", localSelectedCity, localSelectedSubCity);
    }
  }, [localSelectedSubCity]);

  const fetchVillageCenters = async (sido, sigungu, dong, ri) => {
    try {
      const response = await fetch(`${wasUrl}village-hall?sido=${sido}&sigungu=${sigungu}&dong=${dong}&ri=${ri}`);
      const data = await response.json();
      setVillageCenters(data);
    } catch (error) {
      console.error("Error fetching village centers:", error);
    }
  };

  const handleCompleteClick = () => {
    if (localSelectedCity && localSelectedSubCity && selectedVillageCenter) {
      // 선택한 마을회관의 전체 정보를 찾기
      const selectedCenter = villageCenters.find(center =>
        center.name === selectedVillageCenter
      );

      if (selectedCenter) {
        const selectedAddress = `${localSelectedCity} ${localSelectedSubCity} ${selectedCenter.name}`; // 주소 생성


        setDisplayAddress(`전라남도 함평군 ${selectedAddress}`);
        setSearchAddress("");
        setSelectedCity(localSelectedCity);
        setSelectedSubCity(localSelectedSubCity);
        setLegalCode(selectedCenter.legalCode);
        setLatLng({ lat: selectedCenter.lat, lng: selectedCenter.lng });

        // villageHallId를 writingPage로 전달
        setVillageHallId(selectedCenter.id);

        setIsSimpleAddressPopupOpen(false);
      } else {
        console.error("해당 마을회관에 대한 정보를 찾을 수 없습니다.");
        alert("해당 마을회관에 대한 정보를 찾을 수 없습니다.");
      }
    } else {
      console.warn("읍/면, 리 및 마을회관을 선택해주세요.");
      alert("읍/면, 리 및 마을회관을 선택해주세요.");
    }
  };

  const toggleCityDropdown = () => {
    if (!isSubCityDropdownOpen && !isVillageCenterDropdownOpen) {
      setIsCityDropdownOpen(!isCityDropdownOpen);
    }
  };

  const toggleSubCityDropdown = () => {
    if (localSelectedCity && !isCityDropdownOpen && !isVillageCenterDropdownOpen) {
      setIsSubCityDropdownOpen(!isSubCityDropdownOpen);
    }
  };

  const toggleVillageCenterDropdown = () => {
    if (localSelectedSubCity && !isCityDropdownOpen && !isSubCityDropdownOpen) {
      setIsVillageCenterDropdownOpen(!isVillageCenterDropdownOpen);
    }
  };

  const handleCitySelect = (city) => {
    setLocalSelectedCity(city);
    setLocalSelectedSubCity("");
    setSelectedVillageCenter("");
    setIsCityDropdownOpen(false);
  };

  const handleSubCitySelect = (subCity) => {
    setLocalSelectedSubCity(subCity);
    setSelectedVillageCenter("");
    setIsSubCityDropdownOpen(false);
  };

  const handleVillageCenterSelect = (centerName) => {
    setSelectedVillageCenter(centerName);
    setIsVillageCenterDropdownOpen(false);
  };

  // 위치를 동적으로 계산해 적용하는 함수 (누락된 부분 추가)
  useEffect(() => {
    if (isCityDropdownOpen && cityContainerRef.current && cityOptionsRef.current) {
      const containerRect = cityContainerRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const availableHeight = windowHeight - containerRect.bottom;
      cityOptionsRef.current.style.maxHeight = `${Math.min(280, availableHeight)}px`;
      cityOptionsRef.current.style.top = `${containerRect.bottom}px`;
      cityOptionsRef.current.style.width = `${containerRect.width}px`;
    }

    if (isSubCityDropdownOpen && subCityContainerRef.current && subCityOptionsRef.current) {
      const containerRect = subCityContainerRef.current.getBoundingClientRect();
      const availableHeight = window.innerHeight - containerRect.bottom;
      subCityOptionsRef.current.style.maxHeight = `${Math.min(280, availableHeight)}px`;
      subCityOptionsRef.current.style.top = `${containerRect.bottom}px`;
      subCityOptionsRef.current.style.width = `${containerRect.width}px`;
    }

    if (isVillageCenterDropdownOpen && villageCenterContainerRef.current && villageCenterOptionsRef.current) {
      const containerRect = villageCenterContainerRef.current.getBoundingClientRect();
      const availableHeight = window.innerHeight - containerRect.bottom;
      villageCenterOptionsRef.current.style.maxHeight = `${Math.min(280, availableHeight)}px`;
      villageCenterOptionsRef.current.style.top = `${containerRect.bottom}px`;
      villageCenterOptionsRef.current.style.width = `${containerRect.width}px`;
    }
  }, [isCityDropdownOpen, isSubCityDropdownOpen, isVillageCenterDropdownOpen]);

  return isSimpleAddressPopupOpen && (
    <div className="customPopupContainer">
      <div className="customPopupContent">
        {/* 고정된 팝업 헤더 */}
        <div className="popupHeader" style={{ position: 'relative', textAlign: 'center' }}>
          <MapPinIcon width={51} height={54} style={{ '--path-color': '#51CFB2' }} />
          <button
            className="closeButton"
            onClick={() => setIsSimpleAddressPopupOpen(false)}
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              background: 'none',
              border: 'none',
              cursor: 'pointer'
            }}>
            <CloseButton style={{ width: 75, height: 75 }} alt="Close" />
          </button>
        </div>

        <div className="fs-28 fw-7 ta-center pb-30">간편 주소 선택</div>

        {/* 스크롤 가능한 컨텐츠 영역 */}
        <div className="scrollable-content">
          <div className="address-container d-flex a-center j-left mb-30">
            <span className="d-flex a-center j-left fs-22 fw-4 fc-mediumGray2">함평군</span>
          </div>

          {/* 읍/면 선택 */}
          <div className={`custom-select-container ${localSelectedCity ? 'selected' : ''} mb-30`} ref={cityContainerRef} onClick={toggleCityDropdown}>
            <div className="custom-select-trigger fs-22 fw-5 d-flex j-between">
              {localSelectedCity || "읍, 면 선택"}
              <span className="arrow"><img src={underArrow} alt="Dropdown Arrow" /></span>
            </div>
            {isCityDropdownOpen && (
              <div className="custom-options" ref={cityOptionsRef}>
                {cityData.map((city) => (
                  <div key={city.code} className="custom-option" onClick={() => handleCitySelect(city.name)}>
                    {city.name}
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* 리 선택 */}
          <div className={`custom-select-container ${localSelectedSubCity ? 'selected' : ''} ${!localSelectedCity ? 'disabled' : ''} mb-30`} ref={subCityContainerRef} onClick={toggleSubCityDropdown}>
            <div className="custom-select-trigger fs-22 fw-5 d-flex j-between">
              {localSelectedSubCity || "리 선택"}
              <span className="arrow"><img src={underArrow} alt="Dropdown Arrow" /></span>
            </div>
            {isSubCityDropdownOpen && (
              <div className="custom-options" ref={subCityOptionsRef}>
                {cityData.find(city => city.name === localSelectedCity)?.sub.map((subCity) => (
                  <div key={subCity.code} className="custom-option" onClick={() => handleSubCitySelect(subCity.name)}>
                    {subCity.name}
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* 주변 위치 선택 */}
          <div className={`custom-select-container ${selectedVillageCenter ? 'selected' : ''} ${!localSelectedSubCity ? 'disabled' : ''} mb-30`} ref={villageCenterContainerRef}>
            <div className={`custom-select-trigger fs-22 fw-5 d-flex j-between ${!localSelectedSubCity ? 'disabled' : ''}`} onClick={localSelectedSubCity ? toggleVillageCenterDropdown : null}>
              {selectedVillageCenter || "주변위치 선택"}
              <span className="arrow"><img src={underArrow} alt="Dropdown Arrow" /></span>
            </div>
            {isVillageCenterDropdownOpen && (
              <div className="custom-options" ref={villageCenterOptionsRef}>
                {villageCenters.map((center) => (
                  <div key={center.id} className="custom-option" onClick={() => handleVillageCenterSelect(center.name)}>
                    {center.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* 고정된 하단 버튼 */}
        <div className="fixed-footer">
          <LoginButton text="등록" onClick={handleCompleteClick} />
        </div>
      </div>
    </div>

  );
};

export default AddressSelector;