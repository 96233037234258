import React, { useEffect, useState } from "react";
import {useCookies} from "react-cookie";
import Header from "components/header/Header";
import BackHeader from "components/header/BackHeader";
import {pageLoadingEvent} from "utils/commonUtil";
import {useGetRentListApi} from "hooks/centerHooks";
import "assets/css/center.scss";
import coinIcon from "assets/icon/coin.svg";
import calendarIcon from "assets/icon/calendar.svg";
import clipboardIcon from "assets/icon/clipboard.svg";
import noImage2 from "../../../assets/image/trade/noSmallImage.svg";
import {format} from "date-fns";

const RentList = () => {
  const [list, setList] = useState([]);
  const [cookies] = useCookies(['authToken']);
  const authToken = cookies.authToken;
  const searchData = {page: 1, count: 50};

  useGetRentListApi({setList}, authToken, searchData);

  useEffect(() => {
    pageLoadingEvent();
  });

  // 날짜별로 묶기
  const groupedData = list.reduce((acc, item) => {
    // createDateTime에서 날짜 부분만 추출
    const date = item.createDateTime.split(" ")[0];

    // 날짜가 이미 키로 존재하면 배열에 추가, 없으면 새 배열 생성
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(item);

    return acc;
  }, {});

  // 객체 형태를 배열로 변환
  const groupedList = Object.entries(groupedData).map(([date, items]) => ({
    date,
    items,
  }));

  console.log(groupedList);

  return (
    <>
      <section id="rentList" className="container bc-white">
        <Header />
        <div className='content homeContent scrollbar'>
          <BackHeader title='농업기계 임대 목록' />
          {list.length > 0 &&
            <>
              <div className="inner">
                <div className="mt-20 mb-20 fs-18 hintBox">
                  <p>*한 달 이내 임대 목록까지 저장됩니다.</p>
                  <p>농업기계 임대 관련 자세한 문의는</p>
                  <p className="fw-6">본점: 061-320-2502</p>
                  <p className="fw-6">동부점: 061-320-2899</p>
                  <p><span className="fw-6">서부점: 061-320-2903</span>으로 연락 주시길 바랍니다.</p>
                </div>
              </div>
              {groupedList.map((data, index) => {
                return (
                  <div className="bc-lightGray13" key={index}>
                    <div className="inner pt-15 pb-15">
                      <p className="fw-6 fs-24">{format(new Date(data.date), "yyyy.MM.dd")}</p>
                    </div>
                    {data.items.map((value, index) => {
                      return (
                        <div className="rentBox bc-white d-flex a-center j-left" key={index}>
                          <div className="imgBox">
                            <img
                              src={ `https://www.hampyeong.go.kr/aml/bbs/data/lend_shop/${value.machineImageFileName}` || noImage2}
                              alt="machineImg"
                            />
                          </div>
                          <div className="textBox d-flex a-center j-left">
                            <div>
                              <p className="fs-18 fc-mediumGray2">{value.machineGroupName}</p>
                              <p className="fs-26 fw-6 name">{value.machineName}</p>
                              <p className="d-flex a-center j-left coinBox">
                                <img className="icon" src={coinIcon} alt="코인"/>
                                <span className="fs-22 fw-6">{value.rentPrice}</span>
                                원
                              </p>
                              <p className="fs-22 fw-6 d-flex a-center j-left">
                                <img className="icon" src={calendarIcon} alt="달력" />
                                {format(new Date(value.rentStartDate), "yy.MM.dd")}
                                <span className="fw-4">~</span>
                                {format(new Date(value.rentEndDate), "yy.MM.dd")}
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                );
              })}
            </>
          }
          {list.length === 0 &&
            <div className="noListBox ta-center">
              <img src={clipboardIcon} alt="거래 완료" />
              <p className="fs-22">농업기계 임대 목록이 없어요</p>
            </div>
          }

        </div>
      </section>
    </>
  );
};

export default RentList;