export const dongData = (name) => {
    switch (name) {
        case '함평읍':
            return { lat: 35.0653865, lng: 126.5202537 };
        case '손불면':
            return { lat: 35.1431953, lng: 126.4316547 };
        case '신광면':
            return { lat: 35.1555866, lng: 126.4976989 };
        case '학교면':
            return { lat: 35.0169128, lng: 126.5375547 };
        case '엄다면':
            return { lat: 35.0102052, lng: 126.5159039 };
        case '대동면':
            return { lat: 35.0688636, lng: 126.5314363 };
        case '나산면':
            return { lat: 35.1139672, lng: 126.6090319 };
        case '해보면':
            return { lat: 35.1804669, lng: 126.601299 };
        case '월야면':
            return { lat: 35.1799808, lng: 126.6321488 };
        default:
            return null;
    }
}