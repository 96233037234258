import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TradeDropdownPopup from './TradeDropdownPopup';

const TradeDropdown = ({ dropdownList, selectedElement, setSelectedElement, type }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleDropdownClick = () => {
        setIsOpen(!isOpen);
    };

    const handleElementSelect = (element) => {
        setSelectedElement(element);
        setIsOpen(false);
    };

    return (
        <div className='tradeDropdown d-flex j-center mt-20 mb-20 m-auto'>
            <button
                className='tradeDropdownButton d-flex j-between a-center br-5 ba-2-green8 bc-white'
                style={{ width: '94%', height: 60 }}
                onClick={handleDropdownClick}
            >
                <span className='ml-25 fs-28 fw-6 fc-black1'>{selectedElement}</span>
                <span className='mr-25 fs-24 fc-darkGray21'>▼</span>
            </button>
            {dropdownList.length !== 1 && isOpen && (
                <TradeDropdownPopup
                    list={dropdownList}
                    onClose={() => setIsOpen(false)}
                    onSelect={handleElementSelect}
                    type={type}
                />
            )}
        </div>
    );
};

TradeDropdown.propTypes = {
    dropdownList: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedElement: PropTypes.string.isRequired,
    setSelectedElement: PropTypes.func.isRequired,
};

export default TradeDropdown;
