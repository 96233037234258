import React from "react";
import { useNavigate } from "react-router-dom";
import rightArrow from "assets/icon/rightArrow.svg";
import {getCheckApi} from "../../api/centerApi";
import {openPopup} from "../../utils/commonUtil";
import {useCookies} from "react-cookie";

const MenuLiBox = ({id, text, url, type, status, setStatus}) => {
  const navigate = useNavigate();
  const [cookies] = useCookies(['authToken', 'refreshToken']); // removeCookie 추가
  const authToken = cookies.authToken;

  const openEvent = async () => {
    const result = await getCheckApi(authToken);
    sessionStorage.setItem("userCheck", result);

    if (result) {
      navigate("/center")
    } else {
      openPopup("homePopup");
    }
  }

  return (
    <li
      id={id}
      className={`menuLiBox fs-22 fw-5 d-flex a-center j-between w-100 ${text === status ? "active" : ""}`}
      onClick={() => {
        if (type === "menu" && url) {
          if (url === "/center") {
            openEvent();
          } else {
            navigate(url);
          }
        } else if (type === "category" && setStatus) {
          setStatus(text);
        }
      }}
    >
      {text}
      {type === "menu" &&
        <img
          className="arrowImage"
          src={rightArrow}
          alt="이동"
        />
      }
    </li>
  );
};

export default React.memo(MenuLiBox);