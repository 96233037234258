import {closePopup} from "../../utils/commonUtil";

const MessagePopup = (
  { id, text, subText }
) => {
  return (
    <div
      id={id}
      className="popupContainer termsPopup d-flex a-center j-center d-none"
    >
      <div className="popupBody bc-white scrollbar">
        <div className="inner">
          <div className="textArea ta-center">
            <div className="popupText">
              {text &&
                <p
                  className="fs-28 fw-7 mb-30"
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              }
              {subText &&
                <p
                  className=" fs-22 fw-5"
                  dangerouslySetInnerHTML={{ __html: subText }}
                />
              }
            </div>
          </div>
          <div className="btnArea d-flex a-center j-between fs-24 fw-7 fc-white">
            <button
              className="oneBtn bc-green1"
              onClick={() => closePopup(id)}
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagePopup;