import React from "react";
import { ReactComponent as RightArrow } from "assets/icon/rightArrow.svg";

const PageNav = ({ totalItems, currentPage, setCurrentPage, pageContentAmount = 6 }) => {
    // 전체 페이지 수 계산
    const pageCount = Math.ceil(totalItems / pageContentAmount);

    // 페이지 범위 계산
    const startPage = Math.floor((currentPage - 1) / 5) * 5 + 1;
    const endPage = Math.min(startPage + 4, pageCount);

    // 이전/다음 그룹 이동 가능 여부
    const isFirstPageGroup = startPage === 1;
    const isLastPageGroup = endPage >= pageCount;

    const gotoPrev = () => {
        if (!isFirstPageGroup) {
            setCurrentPage(startPage - 5);
        }
    };

    const gotoNext = () => {
        if (!isLastPageGroup) {
            setCurrentPage(startPage + 5);
        }
    };

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    // 페이지가 1개일 때 페이지네이션을 숨김
    if (pageCount <= 1) return null;

    return (
        <div className="inner">
            <div
                className={`pageNavigator d-flex a-center mb-30 ${pageCount <= 5 ? 'j-center' : 'j-between'}`}
                style={{ width: '100%' }}
            >
                {pageCount > 5 && (
                    <button
                        className={`previous d-flex a-center j-center br-6 ba-1-mediumGray25 bc-white ${pageCount > 5 ? "op" : ""}`}
                        style={{ width: 48, height: 48 }}
                        onClick={gotoPrev}
                        disabled={isFirstPageGroup}
                    >
                        <RightArrow style={{ width: 24, height: 24, transform: 'rotate(180deg)' }} />
                    </button>
                )}
                <div className="pages d-flex f-row a-center j-between" style={{ width: pageCount <= 5 ? `${(55 / 5) * pageCount}%` : '55%' }}>
                    {Array.from({ length: endPage - startPage + 1 }, (_, index) => {
                        const page = startPage + index;
                        return (
                            <button
                                key={page}
                                className={`pageBtn d-flex a-center j-center br-6 ${page % 5 === 1 ? '' : 'ml-8'} ${page % 5 === 0 ? '' : 'mr-8'} ${currentPage === page ? 'bc-green1' : 'ba-1-mediumGray25 bc-white'}`}
                                style={{ width: 48, height: 48 }}
                                onClick={() => handlePageClick(page)}
                            >
                                <span className={`fs-24 fw-6 ${currentPage === page ? 'fc-white' : 'fc-black2'}`}>{page}</span>
                            </button>
                        );
                    })}
                </div>
                {pageCount > 5 && (
                    <button
                        className="next d-flex a-center j-center br-6 ba-1-mediumGray25 bc-white"
                        style={{ width: 48, height: 48 }}
                        onClick={gotoNext}
                        disabled={isLastPageGroup}
                    >
                        <RightArrow style={{ width: 24, height: 24 }} />
                    </button>
                )}
            </div>
        </div>
    );
};

export default React.memo(PageNav);
