import BackHeader from "components/header/BackHeader";
import Header from "components/header/Header";
import PageNavigator from "components/pageNav/PageNavigator";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {getParameter, openPopup, pageLoadingEvent} from "utils/commonUtil";
import noImage2 from 'assets/image/trade/noSmallImage.svg';
import { ReactComponent as CenterDownArrow } from 'assets/icon/centerDownArrow.svg';
import { ReactComponent as CenterSafety } from 'assets/icon/centerSafety.svg';
import { ReactComponent as CenterObserve } from 'assets/icon/centerObserve.svg';
import { useCookies } from "react-cookie";
import { useGetRentCategoryApi } from "hooks/centerHooks";
import Terms1 from "components/popup/Terms1";
import Terms2 from "components/popup/Terms2";

const TradeCenterSelect = () => {
    const [list, setList] = useState([]);
    const [cookies] = useCookies(['authToken']);  // 쿠키에서 authToken 가져오기
    const [getResult, setGetResult] = useState(getParameter("result") || false);
    const getResult2 = getParameter("result") || false;
    const [page, setPage] = useState(Number(getParameter("page")) || 1);
    const [totalCount, setTotalCount] = useState(0);
    const getCenter = sessionStorage.getItem("center") || "본점";
    const authToken = cookies.authToken;

    const searchData = {
        page: page,
        count: 8,
        rentSiteCode: getCenter,
    }

    console.log(list)

    useGetRentCategoryApi({setList, setTotalCount}, authToken, searchData);

    const navigate = useNavigate();

    // 디테일 박스 열기/닫기
    const toggleDetail = (e) => {
        const target = e.currentTarget.parentElement; // 부모 요소로 접근
        if (target.classList.contains("active")) {
            target.classList.remove("active");
        } else {
            const centerListElement = document.querySelectorAll(".centerListElement");
            centerListElement.forEach((element) => {
                element.classList.remove("active");
            });
            target.classList.add("active");
        }
        boxResize();
    };


    const goToItemList = (code, name, file) => {
        navigate(`/center/item?code=${code}&name=${name}&file=${file}`);
    };

    // 서브 카테고리 버튼 생성
    const getSubCategories = (data) => {
        return data.map((item, index) => (
            <button
                key={index}
                className="subCategoryBtn a-center j-center mb-15 pl-5 pr-5"
                onClick={() => goToItemList(item.code, item.name, item.imageFileName)}
            >
                <div
                    className="textBox bc-darkGray7 w-100"
                    style={{ height: '25%', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}
                >
                    <p className="fs-20 fw-6 fc-white keep-all">{item.name || '이름 없음'}</p>
                </div>
                <div className="imageBox">
                    <img
                      src={ `https://www.hampyeong.go.kr/aml/bbs/data/lend_shop/${item.imageFileName}` || noImage2}
                      className="d-block"
                      alt="subCategoryThumb"
                    />
                </div>
                <div
                    className="textBox bc-lightGray8 w-100"
                    style={{ height: '15%', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}
                >
                    <span className="fs-18 fw-4 fc-black1">{getCenter}</span>
                </div>
            </button>
        ));
    };

    const boxResize = () => {
        let box = document.querySelectorAll(".imageBox");

        box.forEach((element) => {
            let height = element.offsetWidth;
            element.style.height = `${height}px`;
        });
    }

    window.addEventListener('resize', boxResize);

    useEffect(() => {
        pageLoadingEvent();

        if (getResult) {
            const timeoutId = setTimeout(() => {
                setGetResult(false);
            }, 1500);

            // 컴포넌트 언마운트 시 타이머 제거
            return () => clearTimeout(timeoutId);
        }

    }, []);

    return (
        <>
            <Terms1 />
            <Terms2 />
            <section id="tradeCenterList" className="container bc-white">
                {getResult &&
                  <div className="messageBox fc-white fs-22 fw-6 ls-3 ta-center">임대 신청이 완료되었습니다</div>
                }
                <Header />
                <div className='content homeContent scrollbar'>
                    <BackHeader title='함평군 농업기계 임대 신청' />
                    <div className="inner">
                        <div className="centerListSubTitleBox d-flex f-column a-center j-center ta-left w-100 mt-30 mb-30">
                            <p className="fs-32 fw-7 fc-black1 w-100">
                                함평군 농업기계임대사업소
                            </p>
                            <p className="fs-32 fw-7 fc-black1 w-100">
                                <span className="fc-green1">임대 장비를 선택</span>
                                해주세요
                            </p>
                        </div>
                        <div className="centerListBtnBox d-flex f-row a-center j-left w-100 mb-40">
                            <button
                                className="safetyBtn d-flex f-row a-center j-center bc-lightGray9 mr-15"
                                onClick={() => openPopup("terms2Popup")}
                            >
                                <CenterSafety />
                                <span className="fs-20 fw-6 fc-darkGray10 ml-5">
                                    안전수칙
                                </span>
                            </button>
                            <button
                                className="observedBtn d-flex f-row a-center j-center bc-lightGray9"
                                onClick={() => openPopup("terms1Popup")}
                            >
                                <CenterObserve />
                                <span className="fs-20 fw-6 fc-darkGray10 ml-5">
                                    준수사항
                                </span>
                            </button>
                        </div>
                        <div className="centerListBody d-flex f-column a-center j-center w-100 mb-10">
                            {list.map((item, index) => {
                                return (
                                    <div key={index} className="centerListElement d-flex f-column a-center j-between w-100 mb-30 mt-30">
                                        <button
                                            className="centerListVisible d-flex f-row a-center j-between w-100 mb-30"
                                            onClick={(e) => toggleDetail(e)}
                                        >
                                            <div
                                                className="imgBox"
                                                style={{ width: '13%', aspectRatio: 1.0, overflow: "hidden", borderRadius: 999 }}
                                            >
                                                <img
                                                  src={ `https://www.hampyeong.go.kr/aml/bbs/data/lend_shop/${item.imageFileName}` || noImage2}
                                                  /*src={noImage2}*/
                                                  alt="machineImg"
                                                  style={{ width: '100%', height: '100%' }}
                                                />
                                            </div>
                                            <div
                                                className="textBox d-flex f-row a-center j-between"
                                                style={{ width: '85%' }}
                                            >
                                                <div className="d-flex f-column a-center j-center ta-left">
                                                    <span className="fs-26 fw-7 fc-darkGray2 w-100 keep-all">{item.name || '이름 없음'}</span>
                                                    <span className="fs-22 fw-4 fc-mediumGray2 w-100">{getCenter}</span>
                                                </div>
                                                <CenterDownArrow />
                                            </div>
                                        </button>
                                        <div
                                            className="centerListHiddenBox a-start j-left w-100 d-none"
                                        >
                                            {getSubCategories(item.machineGroupList) || '카테고리 없음'}
                                        </div>
                                        {item.id !== list[list.length - 1].id && (
                                            <div
                                                className="divideLine mt-30"
                                                style={{ width: '100%', height: 0.5, background: '#C5C5C5' }}
                                            />
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                        <PageNavigator totalItems={totalCount} currentPage={page} setCurrentPage={setPage} pageContentAmount={8} />
                    </div>
                </div>
            </section>
        </>
    );
};

export default TradeCenterSelect;
