import React from "react";
import { useNavigate } from "react-router-dom";
import {getCheckApi} from "../api/centerApi";
import {useCookies} from "react-cookie";
import {openPopup} from "../utils/commonUtil";

const TwoImageBox = ({ id, image1, image2, url1, url2, alt1, alt2  }) => {
  const navigate = useNavigate();
  const [cookies] = useCookies(['authToken']);
  const authToken = cookies.authToken;

  const openEvent = async () => {
    const result = await getCheckApi(authToken);
    sessionStorage.setItem("userCheck", result);

    if (result) {
      navigate("/center")
    } else {
      openPopup("homePopup");
    }
  }

  return (
    <div
      id={id}
      className="twoImageBox d-flex a-center j-between"
    >
      <button
        onClick={() => { if (url1) navigate(url1) }}
      >
        <img
          className="d-block"
          src={image1}
          alt={alt1}
          loading="lazy"
        />
      </button>
      <button onClick={openEvent}>
        <img className="d-block" src={image2} alt={alt2} loading="lazy" />
      </button>
    </div>
  );
};

export default React.memo(TwoImageBox)