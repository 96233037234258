import { useEffect, useState, useRef } from "react";
import FarmMachine from 'assets/marker/farm-machine.svg';
import FarmLand from 'assets/marker/farm-land.svg';
import FarmProduct from 'assets/marker/farm-product.svg';
import EtcMarker from 'assets/marker/etc.svg';
import ManPowerMarker from 'assets/marker/manPower.svg';
import MyLocate from 'assets/marker/mylocate.svg';
import numberBall from 'assets/marker/number-ball.svg';
import { useNavigate } from 'react-router-dom';
import { dongData } from "data/dongData.js";
// import { getListFromMarker } from "api/map";

const useKakaoMap = (kakaoMapClientId) => {
    const mapElement = useRef(null);
    const [map, setMap] = useState(null);
    const [centerMarker, setCenterMarker] = useState(null);
    const [allMarkers, setAllMarkers] = useState([]); // 모든 마커를 저장
    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(false);
    const getCenterMarker = () => centerMarker;
    const [centerInfo, setCenterInfo] = useState(null);
    const [dragend, setDragend] = useState(false);

    useEffect(() => {
        const initializeMap = () => {
            return new Promise((resolve) => {
                const script = document.createElement('script');
                script.src = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=${kakaoMapClientId}&libraries=services&autoload=false`;
                script.async = true;

                script.onload = () => {
                    window.kakao.maps.load(() => {
                        const kakao = window.kakao;

                        const mapOptions = {
                            center: new kakao.maps.LatLng(35.0653, 126.5168),
                            level: 5,
                        };

                        const mapInstance = new kakao.maps.Map(mapElement.current, mapOptions);
                        mapInstance.setMinLevel(3);
                        mapInstance.setMaxLevel(8);

                        const latlng = mapInstance.getCenter();
                        const tempInfo = {
                            level: mapInstance.getLevel(),
                            lat: latlng.getLat(),
                            lng: latlng.getLng(),
                        };
                        setCenterInfo(tempInfo);

                        kakao.maps.event.addListener(mapInstance, 'zoom_changed', function () {

                            const level = mapInstance.getLevel();
                            const latlng = mapInstance.getCenter();
                            const tempInfo = {
                                level: level,
                                lat: latlng.getLat(),
                                lng: latlng.getLng(),
                            };
                            setCenterInfo(tempInfo);
                            resolve(); // 비동기 처리 완료를 알림
                        });

                        kakao.maps.event.addListener(mapInstance, 'dragend', function () {
                            const center = mapInstance.getCenter();
                            const level = mapInstance.getLevel();
                            const tempInfo = {
                                lat: center.getLat(),
                                lng: center.getLng(),
                                level: level,
                            };
                            setCenterInfo(tempInfo);
                            setDragend(true);
                            resolve();
                        });

                        setMap(mapInstance);
                        setIsLoaded(true);
                    });
                };

                document.head.appendChild(script);
            });
        };

        initializeMap().then(() => {
            console.log('Map loaded and centerInfo is set.');
        });
    }, [kakaoMapClientId]);

    const getCoordinates = (address, callback) => {
        if (!isLoaded || !map) {
            console.error('Kakao Maps API가 로드되지 않았습니다.');
            return;
        }

        if (address.includes('전체')) {
            const latlng = findDongData(address);
            const moveLocation = new window.kakao.maps.LatLng(latlng.lat, latlng.lng);
            map.setCenter(moveLocation);
            callback(findDongData(address));
        } else {
            const geocoder = new window.kakao.maps.services.Geocoder();
            geocoder.addressSearch(address, function (result, status) {
                if (status === window.kakao.maps.services.Status.OK) {
                    const { x, y } = result[0];
                    const moveLatLon = new window.kakao.maps.LatLng(y, x);
                    map.setCenter(moveLatLon);
                    callback({ lat: y, lng: x });
                } else {
                    console.error('주소를 찾을 수 없습니다.', status);
                }
            });
        }
    };

    const findDongData = (address) => {
        const match = address.match(/([가-힣]+[읍|면])/);
        if (match) {
            const dong = match[1]; // 매칭된 첫 번째 그룹을 반환
            return dongData(dong); // 추출된 '읍/면'을 기준으로 dongData 함수 호출
        }
    }

    const filterMarkersByCategory = (category, list) => {
        const categoryMap = {
            '농기계': [1, 6, 7, 8, 9, 10, 11, 12],
            '농지': [2, 13, 14, 15, 16, 17, 18, 19],
            '인력': [3, 20, 21, 22],
            '농산물': [4, 23, 24, 25, 26, 27],
            '기타': [5],
            '전체': '전체'
        };

        if (!map || !category || !allMarkers.length) return;

        let dataList = list

        const selectedCategoryIds = categoryMap[category];

        if (category !== '전체') {
            // tradeCountList에서 해당 카테고리의 categoryId에 속한 항목만 필터링
            dataList[0].tradeCountList = list[0].tradeCountList.filter(item =>
                selectedCategoryIds.includes(item.categoryId)
            );
        }

        if (category === "전체") {
            createMarkers(dataList);
        } else {
            createMarkers(dataList, selectedCategoryIds[0]);
        }



        /*allMarkers.forEach(marker => {
            const { categoryId } = marker.customData || {};
            if (category === '전체' || categoryMap[category].includes(categoryId)) {
                marker.setMap(map); // 선택된 카테고리의 마커만 지도에 표시
            } else {
                marker.setMap(null); // 다른 카테고리는 숨김
            }
        });*/
    };

    const createMarkers = (markerData, category) => {
        if (!map) return;

        // 기존 마커 초기화
        // allMarkers.forEach(marker => marker.setMap(null));  // 지도에서 마커 제거
        // setAllMarkers([]);  // 마커 목록 초기화

        // 새로운 마커 배열 생성
        // const newMarkers = [];


        // markerData로 마커 생성
        markerData.forEach(({ id, lat, lng, tradeCountList }) => {
            // 같은 카테고리를 가진 tradeCountList의 count 합산
            const categoryCounts = {};
            let totalCount = 0;

            tradeCountList.forEach(({ categoryId, count }) => {
                // 동일한 categoryId에 대해 count를 합산
                if (!categoryCounts[categoryId]) {
                    categoryCounts[categoryId] = 0;
                }
                categoryCounts[categoryId] += count;
                totalCount += count; // 전체 count도 합산
            });

            // 가장 큰 categoryId 값을 찾고, 그에 맞는 마커 이미지 선택
            const firstCategoryId = Math.min(...Object.keys(categoryCounts).map(Number));

            let markerImage = null;

            // categoryId에 따른 마커 이미지 결정
            if ((firstCategoryId >= 6 && firstCategoryId <= 12) || firstCategoryId === 1) {
                markerImage = FarmMachine;
            } else if ((firstCategoryId >= 13 && firstCategoryId <= 19) || firstCategoryId === 2) {
                markerImage = FarmLand;
            } else if ((firstCategoryId >= 20 && firstCategoryId <= 22) || firstCategoryId === 3) {
                markerImage = ManPowerMarker;
            } else if ((firstCategoryId >= 23 && firstCategoryId <= 27) || firstCategoryId === 4) {
                markerImage = FarmProduct;
            } else if (firstCategoryId === 5) {
                markerImage = EtcMarker;
            }

            const markerPosition = new window.kakao.maps.LatLng(lat, lng);
            const markerContent = document.createElement('div');
            markerContent.className = 'customMarker'
            markerContent.style.position = 'relative'; // 마커가 깨지는 문제 해결을 위해 위치 설정
            markerContent.innerHTML = `
            <img src="${markerImage}" style="width: 64px; height: 69px;" />
            ${totalCount > 1 ? `
                <div style="position: absolute; top: -13px; right: -13px;">
                <img src="${numberBall}" style="width: 36px; height: 36px;" />
                <span style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: white; font-weight: bold;">
                ${totalCount}
                </span>
                </div>` : ''}
                `;

            // 새로운 마커 생성
            const marker = new window.kakao.maps.CustomOverlay({
                position: markerPosition,
                content: markerContent,
                yAnchor: 1.5,
                map: map,
            });
            // 마커 클릭 이벤트 핸들러
            markerContent.onclick = () => {
                // 좌표를 쿼리 파라미터로 전달
                if (totalCount > 1) {
                    if (category) {
                        navigate(`/FromMap?lat=${lat}&lng=${lng}&category=${category}`);
                    } else {
                        navigate(`/FromMap?lat=${lat}&lng=${lng}`);
                    }  // 좌표를 쿼리 파라미터로 전달
                } else {
                    // numberBall이 없는 경우 (count가 1)
                    navigate(`/tradeDetailMain?tradeId=${id}`);  // id 값을 쿼리 파라미터로 전달
                }
            };

            marker.customData = { categoryId: firstCategoryId };
            // marker.setMap(map);
            // newMarkers.push(marker);
        });

        // 새롭게 생성된 마커들을 저장
        // setAllMarkers(newMarkers);  // 마커 배열 상태 업데이트
    };



    // const offsetCoordinates = (coordinates, offsetIndex) => {
    //     const offsetAmount = 0.0002;
    //     return {
    //         lat: coordinates.lat + offsetAmount * (offsetIndex % 3),
    //         lng: coordinates.lng + offsetAmount * Math.floor(offsetIndex / 3)
    //     };
    // };

    const resetMarkers = () => {
        allMarkers.forEach(marker => marker.setMap(null));  // 지도에서 모든 마커 제거
        setAllMarkers([]);  // allMarkers 상태 초기화
    };

    const addCenterMarker = (coordinates) => {
        if (centerMarker) {
            centerMarker.setMap(null);
        }

        const markerPosition = new window.kakao.maps.LatLng(coordinates.lat, coordinates.lng);
        const newMarker = new window.kakao.maps.Marker({
            position: markerPosition,
            map: map,
            image: new window.kakao.maps.MarkerImage(MyLocate, new window.kakao.maps.Size(64, 69))
        });

        setCenterMarker(newMarker);
    };

    return { mapElement, map, getCoordinates, createMarkers, addCenterMarker, resetMarkers, getCenterMarker, filterMarkersByCategory, centerInfo, dragend, setDragend };
};

export default useKakaoMap;