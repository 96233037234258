import React, { useEffect, useState } from "react";
import { addDays, format, isAfter, isBefore, parseISO, startOfWeek } from "date-fns";
import Header from "components/header/Header";
import BackHeader from "components/header/BackHeader";
import { closePopup, getParameter, openPopup, pageLoadingEvent } from "utils/commonUtil";
import { ReactComponent as CenterX } from "assets/icon/centerX.svg";
import { ReactComponent as CheckBtn } from "assets/icon/circle-checkButton.svg";
import { ReactComponent as LineCircle } from "assets/icon/line-circle.svg";
import { ReactComponent as XButton } from "assets/icon/xButton.svg";
import "assets/css/center.scss";
import { useCookies } from "react-cookie";
import { postRentApi } from "../../../api/centerApi";
import MessagePopup from "../../../components/popup/MessagePopup";
import { useNavigate } from "react-router-dom";
import { useGetRentMachineDetailApi } from "../../../hooks/centerHooks";

const TradeCenterDetail = () => {
  const center = sessionStorage.getItem("center");
  const getNo = getParameter("no") || "";
  const getMachine = getParameter("machine") || "";
  const getName = getParameter("name") || "";
  const getRentPrice = Number(getParameter("price")) || 0;
  const getFormat = getParameter("format") || "";
  const getStandard = getParameter("standard") || "";
  const [dayValue, setDayValue] = useState(0);
  const [dayList, setDayList] = useState([]);
  const [checkValue, setCheckValue] = useState(false);
  const [btnActive, setBtnActive] = useState(false);
  // const [type, setType] = useState(null)
  const [disabledDates, setDisabledDates] = useState([]);
  const [cookies] = useCookies(['authToken']);  // 쿠키에서 authToken 가져오기
  const getCenter = sessionStorage.getItem("center") || "본점";
  const authToken = cookies.authToken;
  const navigate = useNavigate();

  useGetRentMachineDetailApi({ setDisabledDates }, authToken, getNo);

  useEffect(() => {
    // if (dayValue > 0 && dayList.length === dayValue && checkValue && type) {
    if (dayValue > 0 && dayList.length === dayValue && checkValue) {
      setBtnActive(true);
    } else {
      setBtnActive(false);
    }
  }, [dayValue, dayList, checkValue])
  // }, [dayValue, dayList, checkValue, type])

  const getFutureDates = () => {
    const dates = [];
    const today = new Date();
    const startDate = startOfWeek(today, { weekStartsOn: 0 }); // 주의 시작을 일요일로 설정

    for (let i = 0; i < 21; i++) {
      const futureDate = addDays(startDate, i);
      dates.push(futureDate);
    }

    return dates;
  };

  // 날짜를 주 단위로 그룹화하는 함수
  const groupByWeek = (dates) => {
    const weeks = [];
    for (let i = 0; i < dates.length; i += 7) {
      weeks.push(dates.slice(i, i + 7));
    }

    return weeks;
  };

  const futureDates = getFutureDates();
  const groupedDates = groupByWeek(futureDates);

  useEffect(() => {
    openPopup("loadingPopup");

    setTimeout(() => {
      pageLoadingEvent();
    }, 2000);
  }, []);

  const dayBtnEvent = (value) => {
    setDayValue(value);
    setDayList([]);
    const td = document.querySelectorAll("tbody td");
    td.forEach((element) => {
      element.classList.remove("active");
    });
  }

  const selectDayEvent = (e) => {
    const target = e.target;
    const value = target.getAttribute("data-value");

    if (!value) return false;
    if (target.classList.contains("disabled")) return false;
    if (dayValue === 0) {
      openPopup("messagePopup2");
      return false;
    }

    const [year, month, day] = value.split('-').map(Number);
    const currentDate = new Date(year, month - 1, day);

    const generateDateString = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const selectedDates = [];

    // 선택된 날짜 기준으로 dayValue만큼 날짜 선택, disabled 요소가 있으면 중단
    for (let i = 0; i < dayValue; i++) {
      const newDate = new Date(currentDate);
      newDate.setDate(currentDate.getDate() + i);
      const newDateString = generateDateString(newDate);

      const dateElement = document.querySelector(`[data-value="${newDateString}"]`);

      if (dateElement === null) {
        openPopup("messagePopup");
        return false;
      }

      selectedDates.push(newDateString);
    }

    // 이미 active 클래스를 가진 요소를 클릭하면 리스트를 초기화하고 모든 active 클래스 제거
    if (target.classList.contains("active")) {
      document.querySelectorAll(".tbody .active").forEach((element) => {
        element.classList.remove("active");
      });
      setDayList([]); // 리스트 초기화
    } else {
      // 기존 선택값을 모두 지우고 새로운 값으로 리스트를 업데이트
      document.querySelectorAll(".tbody .active").forEach((element) => {
        element.classList.remove("active");
      });

      setDayList(() => {
        // 새로운 리스트를 날짜순으로 정렬하고 설정
        return selectedDates.sort((a, b) => new Date(a) - new Date(b));
      });

      // 새로운 날짜들에 active 클래스 추가
      selectedDates.forEach((date) => {
        const dateElement = document.querySelector(`[data-value="${date}"]`);
        if (dateElement) {
          dateElement.classList.add("active");
        }
      });
    }
  };


  const addEvent = async () => {
    const data = {
      machineNo: getNo,
      rentSiteCode: getCenter,
      reservationDate: dayList[0],
      shipmentType: 1, // 자가배송이 기본값으로 변경되었음에 따라 1로 고정
      reservationDays: dayValue
    }

    try {
      console.log(data);
      await postRentApi(data, authToken);
      navigate('/center?isRented=true');
      // window.history.go(-2);
      /*navigate("/center/list?result=true", { replace: true });*/
    } catch (e) {
      console.error(e);

      if (e.message === "예약완료 되었습니다.") {
        navigate('/center?isRented=true');
      }
    }
  }

  return (
    <>
      <MessagePopup
        id="messagePopup"
        text="날짜 선택이 <span class='fc-green1'>불가능</span>합니다"
        subText="해당 날짜는 예약이 불가능합니다.<br/>다른 날짜를 선택해 주세요."
      />
      <MessagePopup id="messagePopup2" text="임대일을 선택해주세요." />
      <div id="checkPopup" className="popupContainer termsPopup d-flex a-center j-center d-none">
        <div className="popupBody bc-white p-relative">
          <XButton width={52} height={52} onClick={() => closePopup("checkPopup")} />
          <div className="inner">
            <div className="popupHead fs-22 fw-7 ls-3">
              사용조건 및 준수사항
            </div>
            <ul className="textArea scrollbar fs-18 fw-5 ls-3">
              <li className="d-flex a-start j-left">
                <LineCircle className="circle" />
                <p>임차인은 농기계를 내 것처럼 아껴서 사용한다.</p>
              </li>
              <li className="d-flex a-start j-left">
                <LineCircle className="circle" />
                <p>사용 후 보관시는 깨끗이 세척한 후 안전한 창고 내에 보관한다.(1일 이상 사용할 때)</p>
              </li>
              <li className="d-flex a-start j-left">
                <LineCircle className="circle" />
                <p>임차 후 발생한 고장수리비는 임차인이 부담한다.</p>
              </li>
              <li className="d-flex a-start j-left">
                <LineCircle className="circle" />
                <p>운전미숙, 부주의 등 임차인 과실로 발생한 고장은 임차인이 책임 수리한다.</p>
              </li>
              <li className="d-flex a-start j-left">
                <LineCircle className="circle" />
                <p>임차인이 농기계의 운반 및 사용 중 발생한 사고에 대해서는 민ㆍ형사상의 모든 책임을 진다.</p>
              </li>
              <li className="d-flex a-start j-left">
                <LineCircle className="circle" />
                <p>사용 중 농기계를 분실 또는 파손하였을 때에는 임차인이 보상한다.</p>
              </li>
              <li className="d-flex a-start j-left">
                <LineCircle className="circle" />
                <p>임대기간 만료시에는 깨끗이 세척 후 지체없이 반납한다.</p>
              </li>
              <li className="d-flex a-start j-left">
                <LineCircle className="circle" />
                <p>임대기간 중 농기계의 유지, 보수 및 기본운영에 필요한 소요경비(수리비, 연료비, 농작업상해공제 등)는 임차인이 부담한다.</p>
              </li>
              <li className="d-flex a-start j-left">
                <LineCircle className="circle" />
                <p>안전사고에 대비하여 임차인은 임대농기계를 대상으로 농기계종합공제에 의무적으로 가입하여야함.(별도 안전사고 부담 각서 제출)</p>
              </li>
              <li className="d-flex a-start j-left">
                <LineCircle className="circle" />
                <p>예약취소시 2일간 임대신청이 정지 됩니다. 우천으로 취소시 임대사업장으로 취소 요청 하시기 바랍니다.</p>
              </li>
            </ul>
            <div className="btnBox">
              <button
                className="checkBtn fs-22 fc-white fw-7 ls-3"
                onClick={() => {
                  closePopup("checkPopup");
                  setCheckValue(true);
                }}
              >
                동의하기
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="resultPopup" className="popupContainer d-flex a-center j-center d-none">
        <div className="popupBody bc-white scrollbar">
          <div className="inner">
            <div className="textArea ta-center fs-28 fw-7">
              <p className="fc-green11">{getName}</p>
              <p className="fc-red1">{dayList.map(date => format(parseISO(date), 'M/d')).join(', ')}</p>
              <p>임대신청 할까요?</p>
            </div>
            <div className="btnArea d-flex a-center j-between fs-24 fw-7 fc-white">
              <button
                className="oneBtn bc-mediumGray9 half"
                onClick={() => closePopup("resultPopup")}
              >
                취소
              </button>
              <button
                className="twoBtn half bc-green1"
                onClick={addEvent}
              >
                임대 신청
              </button>
            </div>
          </div>
        </div>
      </div>
      <section id="tradeCenterDetail" className="container bc-lightGray12">
        <Header />
        <div className="content content4 scrollbar">
          <BackHeader title="함평군 농업기계 임대 신청" />
          <article className="bc-white mb-15">
            <div className="inner bb-1-lightGray17 mb-20">
              <p className="fw-7 fs-28 ls-3 fc-green1 pt-20 mb-20">{getName}</p>
            </div>
            <div className="inner pb-15">
              <p className="fw-7 fs-22 mb-20">장비 정보</p>
              <div className="d-flex a-start j-between fs-20 mb-20">
                <p className="fw-5 fc-mediumGray2">임대장소</p>
                <p className="fw-6 fc-black1">{center}</p>
              </div>
              <div className="d-flex a-start j-between fs-20 mb-20">
                <p className="fw-5 fc-mediumGray2">장비그룹</p>
                <p className="fw-6 fc-black1">{getMachine}</p>
              </div>
              <div className="d-flex a-start j-between fs-20 mb-20">
                <p className="fw-5 fc-mediumGray2">기종명</p>
                <p className="fw-6 fc-black1">{getName}</p>
              </div>
              <div className="d-flex a-start j-between fs-20 mb-20">
                <p className="fw-5 fc-mediumGray2">형식</p>
                <p className="fw-6 fc-black1">{getFormat}</p>
              </div>
              <div className="d-flex a-start j-between fs-20 mb-20">
                <p className="fw-5 fc-mediumGray2">규격</p>
                <p className="fw-6 fc-black1">{getStandard}</p>
              </div>
              <div className="d-flex a-start j-between fs-20 mb-20">
                <p className="fw-5 fc-mediumGray2">임대료</p>
                <div className="ta-right">
                  <p className="fs-28 fc-red1 fw-7">{getRentPrice.toLocaleString()}원</p>
                  <p className="fs-18 fc-black1 d-flex a-center j-right">(임대료 {getRentPrice.toLocaleString()}원 <CenterX className="centerX" /> 1일)</p>
                </div>
              </div>
            </div>
          </article>
          <article className="bc-white mb-15">
            <div className="inner pt-25 pb-20">
              <p className="fw-7 fs-22 mb-20">임대일</p>
              <div className="d-flex a-center j-left">
                <button
                  className={`dayBtn ${dayValue === 1 ? "active fw-6 fs-24" : "fs-22"}`}
                  onClick={() => dayBtnEvent(1)}
                >
                  1
                </button>
                <button
                  className={`dayBtn ${dayValue === 2 ? "active fw-6 fs-24" : "fs-22"}`}
                  onClick={() => dayBtnEvent(2)}
                >
                  2
                </button>
                <button
                  className={`dayBtn ${dayValue === 3 ? "active fw-6 fs-24" : "fs-22"}`}
                  onClick={() => dayBtnEvent(3)}
                >
                  3
                </button>
                <p className="fs-22 fw-5">일 <span className="fc-red1">(최대 임대 일수: 3일)</span></p>
              </div>
            </div>
          </article>
          <article className="bc-white mb-15">
            <div className="inner pt-25 pb-15">
              <p className="fw-7 fs-22 mb-5">임대 예약일</p>
              <p className="fs-18 fw-5 fc-mediumGray2 mb-35">2주 이내의 예약만 가능합니다.</p>
              <table className="dayTable">
                <thead>
                  <tr className="fs-20 fw-5 ta-center">
                    <th className="fc-red3">일</th>
                    <th>월</th>
                    <th>화</th>
                    <th>수</th>
                    <th>목</th>
                    <th>금</th>
                    <th className="fc-blue4">토</th>
                  </tr>
                </thead>
                <tbody className="fs-20 ta-center tbody">
                  {groupedDates.map((week, index) => (
                    <tr key={index}>
                      {week.map((date, idx) => {
                        const formattedDate = format(date, "yyyy-MM-dd"); // 날짜를 M/d 형식으로 변환
                        const isDisabled = disabledDates.includes(formattedDate); // 날짜가 disabledDates에 있는지 확인
                        const isHide = (date) => {
                          const today = new Date();
                          const maxDate = addDays(today, 14); // 오늘부터 14일 이후 날짜 계산

                          return isBefore(date, today) || isAfter(date, maxDate);
                        };

                        return (
                          <td
                            key={idx}
                            data-value={isHide(date) || isDisabled ? '' : formattedDate}
                            className={isHide(date) ? 'none' : isDisabled ? 'disabled' : ''}
                            onClick={!isDisabled ? (e) => selectDayEvent(e) : null} // 비활성화된 날짜는 클릭 불가
                          >
                            {isHide(date) ? '' : isDisabled ? '불가능' : format(date, 'M/d')}
                          </td>
                        );
                      })}

                      {week.length < 7 && [...Array(7 - week.length)].map((_, emptyIdx) => <td key={emptyIdx} className="none"></td>)}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </article>
          <article className="bc-white mb-15">
            <div className="inner pt-25 pb-15" onClick={() => openPopup("checkPopup")}>
              <p className="fw-7 fs-22 mb-15">사용조건 및 준수사항</p>
              <div className={`checkBox d-flex a-center j-left fs-20 ${checkValue ? "active" : ""}`}>
                <CheckBtn className="mr-10" />
                사용조건 및 준수사항을 확인 후 동의합니다.
              </div>
            </div>
          </article>
          {/* 자가배송이 기본값으로 결정됨에 따라, 비활성화됨 */}
          {/* <article className="bc-white">
            <div className="inner pt-25 pb-15">
              <p className="fw-7 fs-22 mb-15">배송 종류</p>
              <div className="d-flex a-center j-left radioBox">
                <input id="shipmentType1" type="radio" name="shipmentType" hidden value={1} onClick={() => setType(1)} />
                <label className="d-flex a-center j-left mr-30" htmlFor="shipmentType1">
                  <span className="back d-block"></span>
                  <p className="fs-22">자가배송</p>
                </label>
                <input id="shipmentType2" type="radio" name="shipmentType" hidden value={2} onClick={() => setType(2)} />
                <label className="d-flex a-center j-left" htmlFor="shipmentType2">
                  <span className="back d-block"></span>
                  <p className="fs-22">센터배송</p>
                </label>
              </div>
            </div>
          </article> */}
        </div>
        <article className="bottomBtnBox bc-white d-flex a-center j-center">
          <div className="inner">
            <button
              className={`commonBarBtn w-100 br-7 fc-white fw-7 fs-28 m-auto ${btnActive ? "bc-green1" : "bc-darkGray20"}`}
              disabled={!btnActive}
              onClick={() => openPopup("resultPopup")}
            >
              임대 신청하기
            </button>
          </div>
        </article>
      </section>
    </>
  );
};

export default TradeCenterDetail;