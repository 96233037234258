import React, { useState, useEffect, useRef } from "react";
import { cityData } from "data/cityData.js";
import { ReactComponent as MapPinIcon } from "assets/icon/map-pinDark.svg";
import { ReactComponent as CloseButton } from "assets/icon/xButton.svg";
import CommonBarBtn from "components/button/CommonBarBtn";
import underArrow from "assets/marker/under-arrow.svg";

const MapAddressPopup = ({
  isOpen,
  onClose,
  onComplete,
  showCloseButton,
  previousCity,
  previousSubCity,
}) => {
  const [selectedCity, setSelectedCity] = useState(previousCity || "");
  const [selectedSubCity, setSelectedSubCity] = useState(previousSubCity || "");
  const [isCityDropdownOpen, setIsCityDropdownOpen] = useState(false);
  const [isSubCityDropdownOpen, setIsSubCityDropdownOpen] = useState(false);

  // Refs for custom select containers
  const cityContainerRef = useRef(null);
  const subCityContainerRef = useRef(null);
  const cityOptionsRef = useRef(null);
  const subCityOptionsRef = useRef(null);


  const resetDropdowns = () => {
    setSelectedCity("");
    setSelectedSubCity("");
    setIsCityDropdownOpen(false);
    setIsSubCityDropdownOpen(false);
  };
  useEffect(() => {
    if (isOpen) {
      resetDropdowns();  // 팝업이 열릴 때 상태 초기화
    }
  }, [isOpen]);

  useEffect(() => {
    if (previousCity) {
      setSelectedCity(previousCity);
    }
    if (previousSubCity) {
      setSelectedSubCity(previousSubCity);
    }
  }, [previousCity, previousSubCity]);

  const handleCitySelect = (city) => {
    setSelectedCity(city);
    setSelectedSubCity(""); // 리를 초기화
    setIsCityDropdownOpen(false);
  };

  const handleSubCitySelect = (subCity) => {
    setSelectedSubCity(subCity);
    setIsSubCityDropdownOpen(false);
  };

  const isButtonActive = selectedCity && selectedSubCity;

  const toggleCityDropdown = () => {
    if (!isSubCityDropdownOpen) {
      setIsCityDropdownOpen(!isCityDropdownOpen);
    }
  };

  const toggleSubCityDropdown = () => {
    if (selectedCity) {
      setIsSubCityDropdownOpen(!isSubCityDropdownOpen);
    }
  };

  // 위치를 동적으로 계산해 적용하는 함수
  useEffect(() => {
    if (isCityDropdownOpen && cityContainerRef.current && cityOptionsRef.current) {
      const containerRect = cityContainerRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      const availableHeight = windowHeight - containerRect.bottom;

      // 옵션이 하단에 맞춰 렌더링되며 공간 부족 시 옵션 크기 조정
      cityOptionsRef.current.style.maxHeight = `${Math.min(280, availableHeight)}px`;
      cityOptionsRef.current.style.top = `${containerRect.bottom}px`;
      cityOptionsRef.current.style.left = '22%';
      cityOptionsRef.current.style.width = '60%';
    }

    if (isSubCityDropdownOpen && subCityContainerRef.current && subCityOptionsRef.current) {
      const containerRect = subCityContainerRef.current.getBoundingClientRect();
      const availableHeight = window.innerHeight - containerRect.bottom;

      subCityOptionsRef.current.style.maxHeight = `${Math.min(280, availableHeight)}px`;
      subCityOptionsRef.current.style.top = `${containerRect.bottom}px`;
      subCityOptionsRef.current.style.left = '22%';
      subCityOptionsRef.current.style.width = '60%';
    }
  }, [isCityDropdownOpen, isSubCityDropdownOpen]);


  // 완료 버튼 클릭 로직 처리
  useEffect(() => {
    const btn = document.getElementById("completeBtn");
    if (btn) {
      btn.onclick = () => {
        if (isButtonActive) {
          const jibun = `전라남도 함평군 ${selectedCity.name} ${selectedSubCity.name}`;
          onComplete(jibun);  // jibun 값을 전달
        }
      };
    }
  }, [isButtonActive, selectedCity, selectedSubCity, onComplete]);


  return isOpen && (
    <div className="customPopupContainer">
      <div className="customPopupContent">
        <div className="popupHeader" style={{ position: 'relative', textAlign: 'center' }}>
          <MapPinIcon width={51} height={54} style={{ '--path-color': '#51CFB2' }} />
          {showCloseButton && (
            <button
              className="closeButton"
              onClick={onClose}
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                background: 'none',
                border: 'none',
                cursor: 'pointer'
              }}>
              <CloseButton style={{ width: 75, height: 75 }} alt="Close" />
            </button>
          )}
        </div>
        <div className="fs-28 fw-7 ta-center pb-10">위치 선택</div>
        <div className="fs-20 fw-5 fc-mediumGray2 ta-center pb-30">지도에서 목록을 볼 위치를 선택해주세요</div>

        <div className="address-container d-flex a-center j-left mb-30">
          <span className="d-flex a-center j-left fs-22 fw-4 fc-mediumGray2">함평군</span>
        </div>

        {/* 읍, 면 선택 */}
        <div className={`custom-select-container ${selectedCity ? 'selected' : ''} mb-30`} ref={cityContainerRef} onClick={toggleCityDropdown}>
          <div className="custom-select-trigger fs-22 fw-5 d-flex j-between">
            {selectedCity?.name || "읍, 면 선택"}
            <span className="arrow"><img src={underArrow} alt="Dropdown Arrow" /></span>
          </div>
          {isCityDropdownOpen && (
            <div className="custom-options" ref={cityOptionsRef}>
              {cityData.map((city) => (
                <div
                  key={city.code}
                  className="custom-option"
                  onClick={() => handleCitySelect(city)}
                >
                  {city.name}
                </div>
              ))}
            </div>
          )}
        </div>

        {/* 리 선택 */}
        <div className={`custom-select-container ${selectedSubCity ? 'selected' : ''} ${!selectedCity ? 'disabled' : ''}  mb-30`} ref={subCityContainerRef} onClick={toggleSubCityDropdown}>
          <div className="custom-select-trigger fs-22 fw-5 d-flex j-between">
            {selectedSubCity?.name || "리 선택"}
            <span className="arrow"><img src={underArrow} alt="Dropdown Arrow" /></span>
          </div>
          {isSubCityDropdownOpen && selectedCity && (
            <div className="custom-options" ref={subCityOptionsRef}>
              <div
                className="custom-option"
                onClick={() => handleSubCitySelect({ name: '전체' })}
              >
                {'전체'}
              </div>
              {selectedCity?.sub.map((subCity) => (
                <div
                  key={subCity.code}
                  className="custom-option"
                  onClick={() => handleSubCitySelect(subCity)}
                >
                  {subCity.name}
                </div>
              ))}
            </div>
          )}
        </div>

        {/* 완료 버튼 */}
        <CommonBarBtn
          id="completeBtn"
          active={isButtonActive}
          text="완료"
          activeText="완료"
        />
      </div>
    </div>
  );
};

export default MapAddressPopup;
