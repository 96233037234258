import React, { useEffect, useState } from 'react';
import { getParameter, pageLoadingEvent } from 'utils/commonUtil';
import BackHeader from 'components/header/BackHeader';
import Header from 'components/header/Header';
import CenterMain from 'assets/image/center/centerMain.png';
import CenterEast from 'assets/image/center/centerEast.png';
import CenterWest from 'assets/image/center/centerWest.png';
import { useNavigate } from 'react-router-dom';
import { useGetRentSiteApi } from "../../../hooks/centerHooks";
import { useCookies } from "react-cookie";
import TradeSnackBar from '../detail/TradeSnackBar';

const TradeCenterSelect = () => {
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [cookies] = useCookies(['authToken']);
    const authToken = cookies.authToken;

    const [showSnackBar, setShowSnackBar] = useState(false);

    useGetRentSiteApi({ setList }, authToken);

    useEffect(() => {
        pageLoadingEvent();

        const isRented = getParameter('isRented');
        if (isRented) {
            snackBarDisplay();

            // URL 파라미터 수정
            const params = new URLSearchParams(window.location.search);
            params.delete('isRented');

            // 브라우저 히스토리에서 URL 업데이트
            const newUrl = `${window.location.pathname}?${params.toString()}`;
            window.history.replaceState({}, document.title, newUrl);
        }
    }, []);


    console.log(list);

    const gotoCenter = (destination) => {
        sessionStorage.setItem("center", destination);
        navigate('/center/list');
    }

    const snackBarDisplay = () => {
        setShowSnackBar(true);
        setTimeout(() => {
            setShowSnackBar(false);
        }, 1000); // 1초 후 스낵바 숨기기
    }

    return (
        <section id="tradeCenterSelect" className="container bc-white">
            <Header />
            <div className='content homeContent scrollbar'>
                <BackHeader title={'함평군 농업기계 임대 신청'} url={'/home'} type={1} />
                <div className='inner'>
                    <div className='subTitle d-flex f-column a-start j-center w-100 mt-40 mb-30'>
                        <span className='fs-32 fw-7 fc-black1'>
                            함평군 농업기계임대사업소
                        </span>
                        <span className='fs-32 fw-7 fc-black1'>
                            <span className='fc-green1'>지점을 선택</span>
                            해주세요
                        </span>
                    </div>
                    <div className='d-flex f-row a-center j-between w-100 mb-25'>
                        <button
                            style={{
                                width: '47%',
                            }}
                            onClick={() => gotoCenter("본점")}
                        >
                            <img src={CenterMain} alt="westCenterImg" />
                        </button>
                        <button
                            style={{
                                width: '47%',
                            }}
                            onClick={() => gotoCenter("동부점")}
                        >
                            <img src={CenterEast} alt="westCenterImg" />
                        </button>
                    </div>
                    <div className='d-flex f-row a-center j-left w-100'>
                        <button
                            style={{
                                width: '47%',
                            }}
                            onClick={() => gotoCenter("서부점")}
                        >
                            <img src={CenterWest} alt="westCenterImg" />
                        </button>
                    </div>
                </div>
            </div>
            {showSnackBar &&
                <TradeSnackBar
                    message={'임대 신청이 완료되었습니다.'}
                />
            }
        </section>
    );
};

export default TradeCenterSelect;