import React, { useState, useEffect } from 'react';
import Header from 'components/header/Header';
import Footer from 'components/footer/MapFooter';
import useKakaoMap from './useKakaoMap';
import MapAddressPopup from './MapAddressPopup';
import MapCategoryModal from './MapCategoryModal';
import BackHeader from 'components/header/BackHeader';
import { pageLoadingEvent } from 'utils/commonUtil';
import underArrow from 'assets/marker/under-arrow.svg';
import { findMarkerZoomIn } from "api/map";

const KakaoMap = () => {
  const [isSimpleAddressPopupOpen, setIsSimpleAddressPopupOpen] = useState(true);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('전체');
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [list, setList] = useState([]);

  const kakaoMapClientId = 'fb53addf7ce201a1e5855817ea56f4de';
  const {
    mapElement,
    getCoordinates,
    createMarkers,
    addCenterMarker,
    filterMarkersByCategory,
    resetMarkers,
    getCenterMarker,
    map,
    centerInfo,
    dragend,
    setDragend,
  } = useKakaoMap(kakaoMapClientId);

  useEffect(() => {
    pageLoadingEvent();
  }, []);

  useEffect(() => {
    // 드래그되거나, 카테고리변경되거나, 레벨값이 변화할 경우
    if (dragend) {
      setDragend(false);  // dragend 상태를 다시 false로 설정
      dragendEvent(centerInfo);
    } else {
      dragendEvent(centerInfo);
    }
  }, [dragend, setDragend, centerInfo, selectedCategory]);

  const categoryCode = (category) => {
    switch (category) {
      case '농기계':
        return 1;
      case '농지':
        return 2;
      case '인력':
        return 3;
      case '농산물':
        return 4;
      case '기타':
        return 5;
      default:
        return null;
    }
  }

  const levelRadius = (level) => {
    let unitRadius;
    let mapHeightPerRadius;
    switch (level) {
      case 3:
        unitRadius = 50;
        mapHeightPerRadius = 15;
        break;
      case 4:
        unitRadius = 100;
        mapHeightPerRadius = 15;
        break;
      case 5:
        unitRadius = 250;
        mapHeightPerRadius = 8;
        break;
      case 6:
        unitRadius = 500;
        mapHeightPerRadius = 8;
        break;
      case 7:
        unitRadius = 1000;
        mapHeightPerRadius = 8;
        break;
      case 8:
        unitRadius = 2000;
        mapHeightPerRadius = 8;
        break;
    }
    return unitRadius * mapHeightPerRadius;
  }

  const handleCompleteClick = async (jibun) => {
    removeMarkers();
    getCoordinates(jibun, async (coords) => {
      addCenterMarker(coords);
      try {
        const markerData = await findMarkerZoomIn({
          lat: coords.lat,
          lng: coords.lng,
          radius: 2000,
          categoryId: categoryCode(selectedCategory),
        });

        if (Array.isArray(markerData)) {
          createMarkers(markerData); // createMarkers에 데이터를 전달하여 마커 생성
        } else {
          console.error("Invalid marker data received:", markerData);
        }
      } catch (error) {
        console.error("Error fetching marker data:", error);
      }
    });
    setIsSimpleAddressPopupOpen(false);
  };

  const dragendEvent = async (centerInfo) => {
    removeMarkers();
    try {
      const markerData = await findMarkerZoomIn({
        lat: centerInfo.lat,
        lng: centerInfo.lng,
        radius: levelRadius(centerInfo.level),
        categoryId: categoryCode(selectedCategory),
      });

      if (Array.isArray(markerData)) {
        createMarkers(markerData); // createMarkers에 데이터를 전달하여 마커 생성
      } else {
        console.error("Invalid marker data received:", markerData);
      }
    } catch (error) {
      console.error("Error fetching marker data:", error);
    }
  };

  // 카테고리 필터링
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);  // 선택된 카테고리 상태 저장
    setIsCategoryModalOpen(false);  // 모달 닫기
  };

  const handleResetLocation = () => {
    setShowCloseButton(true);
    setIsSimpleAddressPopupOpen(true);
  };

  const handleSetCurrentLocation = () => {
    const centerMarker = getCenterMarker(); // centerMarker를 가져옴
    if (centerMarker) {
      const position = centerMarker.getPosition(); // 마커의 위치를 가져옴
      map.setCenter(position); // 지도 중심을 마커 위치로 이동
      var latlng = map.getCenter();
      const level = map.getLevel();
      const tempData = {
        lat: latlng.getLat(),
        lng: latlng.getLng(),
        level: level,
      }
      dragendEvent(tempData);
    } else {
      alert("현재 위치 정보가 없습니다.");
    }
  };

  const removeMarkers = () => {
    const markers = document.querySelectorAll('.customMarker'); // customMarker 클래스를 가진 모든 요소를 선택
    markers.forEach(marker => {
      marker.remove(); // 각 요소를 DOM에서 제거
    });
  };

  return (
    <section className='container naver-map'>
      <Header />
      <BackHeader title={'지도에서 찾기'} />
      <div className='content content7' style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            top: '4px',
            left: '50%',
            marginTop: '20px',
            transform: 'translateX(-50%)',
            zIndex: 5,
            width: '80%',
            backgroundColor: 'white',
            borderRadius: '56px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            padding: '15px 20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
            pointerEvents: isSimpleAddressPopupOpen ? 'none' : 'auto',
            border: '2px solid #04AB68'
          }}
          onClick={() => setIsCategoryModalOpen(true)}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className='fw-5 fs-26 fc-mediumGray2'>카테고리 </span>
            <span style={{
              display: 'inline-block',
              width: '1px',
              height: '24px',
              backgroundColor: '#9D9D9D',
              margin: '0 10px'
            }}></span>
            <span className='fw-7 fs-26' style={{ marginLeft: '0' }}>{selectedCategory}</span>
          </div>
          <img src={underArrow} style={{ width: '22px', height: '11px' }} alt='arrowIcon'></img>
        </div>

        <div
          ref={mapElement}
          style={{
            width: '100%',
            height: '100%',
          }}
        />

        <MapCategoryModal
          isOpen={isCategoryModalOpen}
          onClose={() => setIsCategoryModalOpen(false)}
          onSelectCategory={handleCategorySelect}  // 선택된 카테고리를 넘겨줌
        />

        {isSimpleAddressPopupOpen && (
          <MapAddressPopup
            isOpen={isSimpleAddressPopupOpen}
            onClose={() => setIsSimpleAddressPopupOpen(false)}
            onComplete={handleCompleteClick}
            showCloseButton={showCloseButton}
          />
        )}
        <Footer
          onResetLocation={handleResetLocation}
          onSetCurrentLocation={handleSetCurrentLocation}
        />
      </div>
    </section>
  );
};

export default KakaoMap;